import AuthenticatedService from '../../../services/AuthenticatedService';

const CreateBoardAPI = async(boardname, slug) => {
	const BASE_URL = process.env.REACT_APP_BASE_URL;
  const data = {
    "title": boardname,
    "slug": slug,
    "published": true,
  }
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken(); 
  
  try {
    const response = await fetch(`${BASE_URL}/admin/board/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      },
      body: JSON.stringify(data)
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
		  // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
		throw new Error(error.message);
  }
}

export default CreateBoardAPI