import React from 'react';

const Checkbox = ({ id, checked, onChange, label }) => {
  return (
    <div className="flex items-center">
      {/* Hidden native checkbox */}
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        className="hidden peer"
      />
      {/* Custom checkbox appearance */}
      <label htmlFor={id} className="flex items-center cursor-pointer p-1">
        <span
          className={`w-5 h-5 border-2 border-gray-300 
            rounded-md flex items-center justify-center
            peer-checked:border-orange-600 peer-checked:bg-orange-600
            ${checked ? 'border-orange-600 bg-orange-600' : ''}`}
        >
          <svg
            className={`w-4 h-4 text-white 
              ${checked ? 'block' : 'hidden'}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </span>
        <span className="ml-2 text-gray-800 dark:text-white">{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
