import React, { useState } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import SetTheme from './api/SetTheme';
import { FaSpinner } from 'react-icons/fa';
import { useNavigate, useLocation } from "react-router-dom";

export default function CreateBoard() {

  const navigate = useNavigate();
  const location = useLocation();
  const board_id = location.state?.board_id;
  const [isLoading, setIsLoading] = useState(false);
  const [boardError, setBoardError] = useState('');

  const [selectedColor, setSelectedColor] = useState('bg-orange-500');
  const colors = [
    'bg-orange-500',
    'bg-yellow-400',
    'bg-blue-400',
    'bg-cyan-500',
    'bg-cyan-400',
    'bg-teal-500',
    'bg-green-500',
    'bg-lime-500',
    'bg-blue-500',
    'bg-blue-600',
    'bg-indigo-600',
    'bg-fuchsia-600',
    'bg-pink-500',
    'bg-pink-600',
    'bg-red-500',
    'bg-rose-500',
    'bg-rose-950',
    'bg-slate-500',
  ];

  function validateTheme(value) {
    if (value.trim() === '') {
      return 'Please enter the board name.';
    }
    return '';
  }

  // Function to update the color state
  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = validateTheme(selectedColor);
    
    setBoardError(error);

    if (!error) {
      setIsLoading(true);
      try {
        const res = await SetTheme(board_id, selectedColor);
        if (res.status === 200) {
          // setTimeout(function() {
          navigate('/onboard/success', { state: { slug: res.data.slug } });
          // }, 1000);
          setIsLoading(false);
        } else {
          // console.log(res.data)
          setBoardError(res.data)
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error)
        setBoardError("Opps! Some error happened.")
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Change the appearance of your board | Feedvoty</title>
        </Helmet>

        <div className="w-full flex items-center justify-center h-screen bg-gray-50">
          <div className="p-8 rounded-lg w-full md:w-5/12">

            <div className="flex justify-center">
              <img src="../icons/theme.png" alt="" className='w-2/12' />
            </div>
            <h2 className="text-2xl font-bold text-center mb-4">
              Change the appearance
            </h2>
          
            <form>
              <div className="mb-4 pt-4 justify-center">
                {boardError.length > 0 && (
                  <p className="text-red-500 text-sm mt-1">{boardError}</p>
                )}

                <label htmlFor="boardTheme" className="block text-sm font-medium text-gray-700 text-center mb-5">Board tint color</label>
                {colors.map(color => (
                  <button
                    type="button"
                    key={color}
                    className={`h-10 w-10 m-3 lg:m-2 md:m-2 rounded-full 
                      cursor-pointer focus:outline-none focus:ring-2
                      focus:ring-offset-2 focus:ring-gray-500 ${color}
                      ${color} ${selectedColor === color ?
                        'ring-2 ring-offset-2 ring-gray-500' : ''}
                      `}
                    onClick={() => setSelectedColor(color)}
                  />
                ))}
                <div className='flex justify-between mt-10'>
                  <a
                    href="/"
                    >
                    SKIP
                  </a>
                  <button
                    type="button"
                    className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 focus:outline-none focus:bg-orange-600"
                    onClick={handleSubmit}
                    >
                    {isLoading ? <FaSpinner className="animate-spin" /> : 'NEXT'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        
      </HelmetProvider>
    </>
  );
}
