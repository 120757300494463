export default function formatDateTime(isoString) {
  const date = new Date(isoString);

  // Format the date as dd/mm/yy
  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });

  // Format the time as h:i am/pm
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return `${formattedDate} ${formattedTime}`;
}