import Cookies from 'js-cookie';

class AuthenticatedService {

    registerSuccessfulLogin(token, refreshToken, subdomain) {
        const tokenExpirationDate = new Date();
        tokenExpirationDate.setMinutes(tokenExpirationDate.getMinutes() + 90); // Sets token expiry to 90 minutes
    
        const refreshTokenExpirationDate = new Date();
        refreshTokenExpirationDate.setMonth(refreshTokenExpirationDate.getMonth() + 3); // Sets refresh token expiry to 3 months

        // Set the domain attribute to the subdomain
        let domain = `${subdomain}`;
        if (window.location.host === 'localhost:3000') {
            domain = 'localhost';
        } else {
            const parts = window.location.hostname.split('.').slice(-2);
            domain = parts.join('.');
        }

        Cookies.set('token', token, { 
            expires: tokenExpirationDate, 
            secure: true, 
            httpOnly: false,
            domain: domain
        });
        Cookies.set('refresh_token', refreshToken, { 
            expires: refreshTokenExpirationDate, 
            secure: true, 
            httpOnly: false,
            domain: domain 
        });
    }    

    async isUserLoggedIn() {
        const token = this.getToken();
        if (!token || this.hasTokenExpired(token)) {
            const refreshToken = this.getRefreshToken();
            if (refreshToken && !this.hasTokenExpired(refreshToken)) {
                try {
                    const newTokens = await this.refreshToken(refreshToken);
                    if (newTokens) {
                        this.registerSuccessfulLogin(newTokens.token, newTokens.refreshToken, window.location.hostname);
                        return true;
                    } else {
                        return false;
                    }
                } catch (error) {
                    console.error('Error during token refresh:', error);
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }


    getToken() {
        return Cookies.get("token");
    }

    getRefreshToken() {
        return Cookies.get("refresh_token");
    }

    async refreshToken(refreshToken) {
    	const BASE_URL = process.env.REACT_APP_BASE_URL;

        const response = await fetch(`${BASE_URL}/auth/refresh/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refresh_token: refreshToken }),
        });
        const data = await response.json();
        // console.log(data)
        if (data.token && data.refresh_token) {
            return {
                token: data.token,
                refreshToken: data.refresh_token
            };
        }
        return null;
    }

    removeTokens(subdomain) {
        // Set the domain attribute to the subdomain
        let domain = `${subdomain}`;
        if (window.location.host === 'localhost:3000') {
            domain = 'localhost';
        } else {
            const parts = window.location.hostname.split('.').slice(-2);
            domain = parts.join('.');
        }

        Cookies.set('token', "", { 
            secure: true, 
            httpOnly: false,
            domain: domain
        });
        Cookies.set('refresh_token', "", { 
            secure: true, 
            httpOnly: false,
            domain: domain 
        });
        Cookies.remove("token", { secure: true, domain: domain });
        Cookies.remove("refresh_token", { secure: true, domain: domain });
    }

    hasTokenExpired(token) {
        // const token = this.getToken();
        if (!token) {
            return true; // Token is missing, consider as expired
        }

        const tokenPayload = token.split('.')[1];
        const decodedTokenPayload = atob(tokenPayload);
        const tokenData = JSON.parse(decodedTokenPayload);

        const expirationTimestamp = tokenData.exp;
        if (!expirationTimestamp) {
            return true; // If no expiration timestamp, consider token as expired
        }

        const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix timestamp format
        return expirationTimestamp <= currentTime;
    }
}

const authenticatedServiceInstance = new AuthenticatedService();
export default authenticatedServiceInstance;