import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const UserSelect = ({ users, onUserChange, onCreateUser }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);

  const [isDarkMode, setIsDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => setIsDarkMode(mediaQuery.matches);
    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  useEffect(() => {
    if (!inputValue || !inputValue.includes('+ Create')) {
      setSelectedUser(null);
    }
  }, [inputValue]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: '0.1rem 0rem',
      borderWidth: state.isFocused ? '1px' : '1px',
      borderColor: state.isFocused ? '#F97315' : '#E5E7EB', // Change border color on focus
      boxShadow: state.isFocused ? '0 0 0 1px #F97315' : 'none',
      backgroundColor: isDarkMode ? '#1F2937' : '#FFFFFF',
      color: isDarkMode ? '#F9FAFB' : '#111827',
      '&:hover': {
        borderColor: '#F97315', // Change border color on hover
      },
      '&:focus': {
        borderColor: '#F97315', // Change border color on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: isDarkMode ? 
        (state.isFocused ? '#2D3748' : (state.isSelected ? '#4A5568' : '#1F2937')) : 
        (state.isFocused ? '#E2E8F0' : (state.isSelected ? '#EDF2F7' : '#FFFFFF')),
      color: isDarkMode ? '#F9FAFB' : '#111827',
      ':active': {
        backgroundColor: isDarkMode ? '#4A5568' : '#E2E8F0',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? '#1F2937' : '#FFFFFF',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarkMode ? '#F9FAFB' : '#111827',
    }),
    input: (provided) => ({
      ...provided,
      color: isDarkMode ? '#F9FAFB' : '#111827',
    }),
  };


  const handleChange = (option) => {
    if (option?.isCreate) {
      onCreateUser(inputValue.replace('+ Create "', '').replace('"', ''));
      setInputValue('');
    } else {
      setSelectedUser(option);
      if (option) {
        onUserChange(option.value);
      } else {
        onUserChange(null);
      }
    }
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const options = users.map(user => ({ value: user.id, label: user.full_name }));
  if (inputValue && !users.find(user => user.full_name === inputValue)) {
    options.push({ value: inputValue, label: `+ Create new user`, isCreate: true });
  }

  return (
    <Select
      value={selectedUser}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      placeholder="Select or create user..."
      className="w-full border border-gray-300 rounded-md"
      classNamePrefix="select"
      isClearable={true}
      styles={customStyles}
    />
  );
};

export default UserSelect;
