// CustomDateRangePicker.jsx
import React, { useState, useRef, useEffect } from 'react';
import { format, subDays } from 'date-fns';
import { MdCalendarMonth } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";

const CustomDateRangePicker = ({ onDateChange }) => {
  // const [startDate, setStartDate] = useState(format(subDays(new Date(), 30), 'yyyy-MM-dd'));
  const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);
  const [calenderLabel, setCalenderLabel] = useState('All Time');
  const calendarRef = useRef(null);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    // onDateChange(event.target.value, endDate);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    // onDateChange(startDate, event.target.value);
  };

  const handlePresetClick = (preset) => {
    setStartDate(preset.start);
    setEndDate(preset.end);
    onDateChange(preset.start, preset.end);
    setCalenderLabel(preset.label);
    setShowCalendar(false);
  };

  const handleDateRange = () => {
    if (startDate && endDate) {
      onDateChange(startDate, endDate);
      const startDateFormatted = format(new Date(startDate), 'dd/MM/yy');
      const endDateFormatted = format(new Date(startDate), 'dd/MM/yy');
      // console.log(startDateFormatted)
      setCalenderLabel(`${startDateFormatted}-${endDateFormatted}`);
    }
    setShowCalendar(false);
  }

  const presets = [
    { label: 'Today', start: format(new Date(), 'yyyy-MM-dd'), end: format(new Date(), 'yyyy-MM-dd') },
    { label: 'Yesterday', start: format(subDays(new Date(), 1), 'yyyy-MM-dd'), end: format(subDays(new Date(), 1), 'yyyy-MM-dd') },
    { label: 'This Week', start: format(subDays(new Date(), 6), 'yyyy-MM-dd'), end: format(new Date(), 'yyyy-MM-dd') },
    { label: 'Last Week', start: format(subDays(new Date(), 13), 'yyyy-MM-dd'), end: format(subDays(new Date(), 7), 'yyyy-MM-dd') },
    { label: 'This Month', start: format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'), end: format(new Date(), 'yyyy-MM-dd') },
    { label: 'Last Month', start: format(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), 'yyyy-MM-dd'), end: format(new Date(new Date().getFullYear(), new Date().getMonth(), 0), 'yyyy-MM-dd') },
    { label: 'All Time', start: null, end: null },
  ];

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <div className="cursor-pointer" onClick={() => setShowCalendar(!showCalendar)}>
        <div className="flex p-2 w-full justify-between border border-gray-300 
          rounded-b-md bg-white 
          dark:bg-gray-700 dark:text-gray-300 mb-5 text-sm">
          
          {/* {startDate} - {endDate} */}
          <MdCalendarMonth size={20} />
          {calenderLabel}
          <IoIosArrowDown size={20} />
        </div>
      </div>
      {showCalendar && (
        <div ref={calendarRef} className="absolute top-full left-0 z-50 
          bg-white dark:bg-gray-700 shadow-lg p-4 rounded w-full">
          <div className="font-bold text-sm text-gray-900 dark:text-white">Relative Dates</div>

          <div className="flex flex-col mb-4 mt-2">
            {presets.map((preset) => (
              <button
                key={preset.label}
                onClick={() => handlePresetClick(preset)}
                className="mb-2 text-left text-sm text-gray-700 
                  dark:text-gray-100
                  hover:text-orange-500 focus:outline-none"
              >
                {preset.label}
              </button>
            ))}
          </div>

          <div className="font-bold text-sm text-gray-900 dark:text-white">Fixed Date</div>

          <div className="text-center mt-2">
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              className="border p-2 rounded mb-2 bg-white dark:bg-gray-700
              dark:text-gray-200"
            />
            <div className='dark:text-gray-300'>
              To
            </div>
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              className="border p-2 rounded mt-2 bg-white dark:bg-gray-700
                dark:text-gray-200"
            />
          </div>
          <button
            onClick={handleDateRange}
            className="mt-4 w-full p-2 bg-orange-500 text-white rounded 
            hover:bg-orange-600 focus:outline-none"
          >
            Apply
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomDateRangePicker;
