import {
  IoIosAdd,
  IoIosCheckbox,
  IoIosArrowBack,
  IoIosArrowForward
} from "react-icons/io";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { useState } from "react";
import { Link } from "react-router-dom";
// import { setDate } from "date-fns";
import CustomDateRangePicker from '../../../../components/CustomDateRangePicker';

const Filter = ({
  isSidebarOpen,
  setIsSidebarOpen,
  boards,
  checkedBoards,
  toggleBoardChecked,
  toggleStatusChecked,
  status,
  handleFilter
}) => {

  const [startDate, setStartDate] = useState(null); 
  const [endDate, setEndDate] = useState(null); 
  const [dateFilter, setDateFilter] = useState('post');

  const callHandleFilter = (dateFilter, startDate, endDate) => {
    handleFilter(dateFilter, startDate, endDate);
  }

  const onDateChange = (startDateParams, endDateParams) => {
    // console.log(`${startDate} ${endDate}`)
    // handleFilter(dateFilter, startDate, endDate);
    setStartDate(startDateParams);
    setEndDate(endDateParams);
    callHandleFilter(dateFilter, startDateParams, endDateParams);
  }

  const handleFilterType = (filterType) => {
    setDateFilter(filterType);
    handleFilter(filterType, startDate, endDate);
  }

  return (
    <>
      {/* Sidebar toggle button */}
      <button
        className={`absolute ${isSidebarOpen ? 'left-64 md:hidden' : 'left-0'}  
          p-0 z-10 top-1/2`}
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ?
          <div
            className='border-2 border-orange-500 h-16 rounded-md'
          >
            <IoIosArrowBack
              size={7}
              className='h-14 text-orange-500 font-bold'
            />
          </div>
          :
          <div
            className='border-2 border-orange-500 h-16 rounded-md'
          >
            <IoIosArrowForward
              size={7}
              className='h-14 text-orange-500 font-bold'
            />
          </div>
        }
      </button>

      <div className={`transform w-64 md:mt-0.5 bg-white 
          dark:bg-gray-800 fixed 
          h-full ease-in-out transition-all duration-300 ${
          isSidebarOpen ? "translate-x-0 md:w-2/12 p-3" : "-translate-x-full md:w-0"
        } md:translate-x-0 md:static shadow-2xl md:shadow-sm
        overflow-y-auto z-10`}>

        <div className="font-bold text-sm text-gray-600 dark:text-white">Date range</div>
        
        <div className="flex mt-2">
          <div className={`w-1/2 text-center 
            ${dateFilter === 'post' ? 'bg-orange-500 text-white  font-bold'
              : 'bg-white dark:bg-gray-800 dark:text-gray-300'} 
            border border-gray-300 dark:border-gray-300
            rounded-tl-md text-xs p-1
            hover:cursor-pointer`}
            onClick={() => handleFilterType('post')}
          >
            POSTS
          </div>
          <div className={`w-1/2 text-center 
            ${dateFilter === 'vote' ? 'bg-orange-500 text-white  font-bold'
            : 'bg-white dark:bg-gray-800 dark:text-gray-300'}
            border border-gray-300 dark:border-gray-300
            rounded-tr-md text-xs p-1
            hover:cursor-pointer`}
            onClick={() => handleFilterType('vote')}
          >
            VOTES
          </div>
        </div>

        <div>
          <CustomDateRangePicker onDateChange={onDateChange} />
        </div>

        <div className="flex justify-between">
          <div className='font-bold text-sm text-gray-600 dark:text-white'>
            Boards
          </div>
          {/* <div className='text-gray-600 dark:text-white text-sm cursor-pointer'>
            Select all
          </div> */}
        </div>

        <div className='mt-3'>
          <ul>
            {boards.map((board, index) => (
              <li key={index} className='flex items-center 
                justify-center gap-2.5 mb-1'>
                {checkedBoards[board.slug] && (
                  <IoIosCheckbox
                    className='text-3xl text-orange-500 cursor-pointer'
                    onClick={() => toggleBoardChecked(board.slug)}
                  />
                )}
                {!checkedBoards[board.slug] && (
                  <MdOutlineCheckBoxOutlineBlank
                    className='text-3xl text-orange-500 cursor-pointer'
                    onClick={() => toggleBoardChecked(board.slug)}
                  />
                )}
                <label htmlFor={board.id} className='w-full text-gray-700 
                  dark:text-white font-medium cursor-pointer'>
                  {board.title}
                </label>

                <label htmlFor={board.id}
                  className="border border-gray-800 dark:border-gray-300 
                    px-1 dark:text-gray-300 rounded-sm text-xs">
                  { board.posts_count }
                </label>
              </li>
            ))}
          </ul>
          <div className='mt-3'>
            <Link to="/admin/create-board">
              <div
                className="flex text-gray-500 cursor-pointer
                hover:text-gray-600 dark:text-white hover:dark:text-gray-100"
              >
                <IoIosAdd className='text-2xl' />
                Create new board
              </div>
            </Link>
          </div>
        </div>

        <div className="flex flex-col justify-between mt-4">
          <div className="flex justify-between">
            <div className='font-bold text-sm text-gray-600 dark:text-white'>
              Status
            </div>
            {/* <div className='text-gray-600 dark:text-white text-sm cursor-pointer'>
              Reset
            </div> */}
          </div>

          <div className='mt-3'>
            <ul>
              {status.map((stat, index) => (
                <li key={index} className='flex items-center 
                  justify-center gap-2.5 mb-1'>
                  {stat.is_checked && (
                    <IoIosCheckbox
                      className='text-3xl text-orange-500 cursor-pointer'
                      onClick={() => toggleStatusChecked(stat.status_name)}
                    />
                  )}
                  {!stat.is_checked && (
                    <MdOutlineCheckBoxOutlineBlank
                      className='text-3xl text-orange-500 cursor-pointer'
                      onClick={() => toggleStatusChecked(stat.status_name)}
                    />
                  )}
                  <label htmlFor={stat.id} className='w-full text-gray-700 
                    dark:text-white font-medium cursor-pointer'>
                    {stat.status_name}
                  </label>
                </li>
              ))}
            </ul>
          </div>

        </div>
        
        <div className='hidden md:flex absolute bottom-0 right-0 mb-5 mr-5'>
          <IoIosArrowBack
            size={25}
            className="text-gray-600 dark:text-gray-300 cursor-pointer"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          />
        </div>
      </div>
    </>
  );
};

export default Filter;