import React, { useState } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import CreateBoardAPI from '../../Onboard/api/CreateBoardAPI';
import { FaSpinner } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import BoardForm from '../../BoardForm/BoardForm';
import Navbar from '../../../components/Navbar';

export default function CreateBoard() {

  const navigate = useNavigate();

  const [boardName, setBoardName] = useState('');
  const [boardURL, setBoardURL] = useState('');
  const [boardSlug, setBoardSlug] = useState('');
  const [boardError, setBoardError] = useState('');
  const [responseCreate, setResponseCreate] = useState(false);
  const [responseError, setResponseError] = useState('');
  const [boardCreateSuccess, setBoardCreateSuccess] = useState(false);

  function validateBoardName(value) {
    if (value.trim() === '') {
      return 'Please enter the board name.';
    }
    return '';
  }

  const handleBoard = (value) => {
    console.log(value)
    const error = validateBoardName(value);
    setBoardError(error);

    setBoardName(value)
    // Create a slug by converting to lowercase, replacing spaces with hyphens, and removing non-alphanumeric characters.
    const slug = value
      .toLowerCase()
      .replace(/\s+/g, '-')       // Replace spaces with hyphens.
      .replace(/[^\w-]+/g, '');   // Remove all non-word chars.

    setBoardSlug(slug);
    // Construct the full URL using the window location and the slug.
    let createBoardURL = `${window.location.protocol}//${window.location.host}/${slug}`;

    // Set the board URL state.
    setBoardURL(createBoardURL);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle the submit action here, e.g., send data to an API or state management
    // console.log('Board Name:', boardName);
    // console.log('Board Slug:', boardSlug);
    // console.log('Board URL:', boardURL);
    const error = validateBoardName(boardName);
    setBoardError(error);
    if (!error) {
      try {
        const res = await CreateBoardAPI(boardName, boardSlug);
        
        if (res.status === 201) {
          setResponseError(false)
          setBoardCreateSuccess(true);
          setResponseCreate("Congrats! You have been successfully created another Board.")
          setTimeout(function() {
            navigate('/admin/feedback', { state: { board_id: res.data.id } });
          }, 2000);
        } else {
          setResponseError(true)
          // console.log(res.data)
          setResponseCreate(res.data)
        }
      } catch (error) {
        console.log(error)
        setResponseError(true)
        setResponseCreate("Opps! Some error happened.")
      }
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Create your board | Feedvoty</title>
        </Helmet>

        <Navbar />

        <div className="w-full flex items-center justify-center h-screen 
          bg-gray-50 dark:bg-gray-800">
          <div className="p-8 rounded-lg w-full md:w-5/12">

            <div className="flex justify-center">
              <img src="../icons/corkboard.png" alt="" className='w-2/12' />
            </div>

            <h2 className="text-2xl font-bold text-center mb-2 dark:text-gray-50">
              Create a new board
            </h2>
            <h4 className="text-xl text-center mb-4 dark:text-gray-50">
              A board is a place where people can post and vote on ideas for a specific topic.
            </h4>
            {responseCreate && (
              <div className={`text-center px-7 py-3  border ${responseError ? 'border-red-700 bg-red-100' : 'border-green-700 bg-green-100'} rounded-md`}>
                <p className={`${responseError ? 'text-red-700' : 'text-green-700'} text-lg mt-1`}>
                  {responseCreate}
                </p>
              </div>
            )}

            {!boardCreateSuccess && (
              <BoardForm
                boardName={boardName}
                boardError={boardError}
                boardURL={boardURL}
                handleBoard={handleBoard}
                handleSubmit={handleSubmit}
                submitBtnLabel='CREATE'
              />
            )}  
            <div className="flex justify-center m-5">
            {boardCreateSuccess && (<FaSpinner className="animate-spin" />)}
            </div>
          </div>
        </div>
            
      </HelmetProvider>
    </>
  );
}