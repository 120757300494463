import { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { FaSpinner } from 'react-icons/fa';
import CreatePassword from './api/CreatePassword';

export default function SetPassword() {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  function validatePassword(value) {
    if (value.trim() === '') {
      return 'Please enter password';
    }

    if (value.length < 8) {
      return 'Minimum of 8 characters needed to create password';
    }
    return '';
  }

  function handlePassword(value) {
    const error = validatePassword(value);
    setPassword(value);
    setPasswordError(error);
  }

  const change = async (e) => {
    e.preventDefault();

    const error = validatePassword(password);

    setPasswordError(error);

    if (!error) {
      setIsLoading(true);

      try {
        const res = await CreatePassword(password);
        if (res.status === 200) {
            navigate('/onboard/create-board');
        } else {
          setPasswordError(res.data || 'An unexpected error occurred');
          setIsLoading(false);
        }
      } catch (error) {
        setPasswordError(error.message || 'An unexpected error occurred');
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Set your password | Feedvoty</title>
        </Helmet>
        <div className="w-full min-h-screen flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-12">
          <div className="w-full space-y-8">
            <div className="text-center">
              <img src="./logo.png" alt="logo" className='mx-auto md:w-1/12 w-1/4' />
              <p className="mt-6 text-xl lg:text-3xl text-gray-900">
                Now it's time to set your 🔑 <span className='text-orange-500 font-bold'>password</span>
              </p>
            </div>
            
            <div className="flex justify-center">
              <div className="w-full md:w-3/4 lg:w-1/4">
              {passwordError.length > 0 && (
                <div className="px-7 py-3 border border-red-700 bg-red-100 rounded-md">
                  <p className="text-red-700 text-lg mt-1">
                    {passwordError}
                  </p>
                </div>
              )}
              </div>
            </div>
            <form className="mt-8 space-y-6" action="#" method="POST">
              <div className="flex justify-center">
                <div className="w-full md:w-3/4 lg:w-1/4">
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    type="password"
                    id='password'
                    required
                    className={`appearance-none rounded relative block w-full px-3 py-3 border ${
                      passwordError ? 'border-red-500' : 'border-gray-300'
                    } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm`}
                    placeholder="Enter your password"
                    onChange={(e) => handlePassword(e.target.value)}
                  />
                </div>
              </div>
              
              <div className="flex justify-center">
                <div className="w-full md:w-3/4 lg:w-1/4">
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                    onClick={change}
                  > 
                    {isLoading ? <FaSpinner className="animate-spin" /> : 'SET PASSWORD'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
  
    
}