import React, { useEffect, useState } from 'react';
import { IoIosCheckbox } from "react-icons/io";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";

// import CreatePostApi from '../api/CreatePostApi';
import PostForm from './PostForm';
import { UpdatePost } from '../../Feedback/utils/api';
const UpdatePostForm = ({editCancel, postData, updatePostData}) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [values, setValues] = useState({
    title: '',
    description: '',
  });

  useEffect(() => {
    // console.log(postData)
    if (postData && postData.title) {
      setValues({
        title: postData.title,
        description: postData.content,
      });

      if (postData.attachments != null) {
        setImages(postData.attachments);
      }
    }
  }, [postData]);
  
  const handleChange = (event) => {
    const fieldName = event.target.name;

    // Clear the validation error for the changed field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));

    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  }

  const handleContentEditableChange = (event) => {
    const fieldName = event.target.name;

    // Clear the validation error for the changed field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));

    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  }

  const submit = async (e) => {
    e.preventDefault();
    
    // Clear any previous validation errors
    setValidationErrors({});
    // Validate each field
    const errors = {};

    if (!values.title) {
      errors.title = 'Title is required';
    }

    if (Object.keys(errors).length > 0) {
      // Update the validation errors state
      setValidationErrors(errors);
      return;
    }
    // console.log(values)
    
    const data = {
      title: values.title,
      content: values.description,
      is_private: isPrivate,
      images: images
    }

    // console.log(data)
    const res = await UpdatePost(data, postData.id);
    // console.log(res)
    if (res.status === 200) {
      updatePostData(res.data);
    }
  }

  return (
    <div>
      <div className="md:flex">
        <PostForm
          handleChange={handleChange}
          handleContentEditableChange={handleContentEditableChange}
          values={values}
          validationErrors={validationErrors}
          images={images}
          setImages={setImages}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          BASE_URL={BASE_URL}
          width='full'
        />
      </div>

      <div className='border-t border-gray-300 p-5'>

        <div className='flex justify-between'>
          <div>
            <button
              type='button'
              onClick={editCancel}
              className='dark:text-gray-300'
            >Cancel</button>
          </div>
          <div className='flex'>
            <div className='flex mt-1'>
              {isPrivate ?
                <IoIosCheckbox
                  size={22}
                  className='mt-1 text-orange-500 cursor-pointer'
                  onClick={() => setIsPrivate(!isPrivate)}
                /> :
                <MdOutlineCheckBoxOutlineBlank
                  size={22}
                  className='mt-1 text-gray-400 cursor-pointer'
                  onClick={() => setIsPrivate(!isPrivate)}
                />
              }
              <span className='mt-0.5 mr-10 ml-0.5 text-gray-600 dark:text-gray-200'>
                Private
              </span>
            </div>
            <button className='px-4 py-2 rounded focus:bg-orange-300 
              bg-orange-500 text-white'
              onClick={submit}
              >
              Update post
            </button>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default UpdatePostForm;
