import React from 'react';
import Navbar from '../../../components/Navbar';

export default function Dashboard() {
  return (
    <>
      <Navbar />
      <div className="flex mt-[60px] overflow-y-hidden z-10" style={{ height: 'calc(100vh - 60px)' }}>
        <div className='flex flex-col w-full mt-10 text-center'>Working on it..</div>
      </div>
    </>
  );
}
