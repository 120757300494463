import React from 'react';
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function SendVerification() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Recover your account | Feedvoty</title>
          <meta
            name="description"
            content="Recover your account, Feedvoty" />
        </Helmet>
        <div className="flex">
          <div className="min-h-screen w-full flex flex-col bg-gray-50 px-4 sm:px-6 lg:px-12">
            <div className="w-full space-y-8">
              <div className='text-center justify-center'>
                <div className='flex items-center justify-center pt-12'>
                  <img src="./logo.png" alt="logo" className='w-2/12 md:w-1/12' />
                </div>
                <div className="pt-20">
                  <div className='text-xl lg:text-2xl text-gray-900'>
                    Verification email sent!
                  </div>
                  <div className='text-xl lg:text-xl text-gray-900 mt-2'>
                    Please check your inbox, we have sent an email to recover your account.
                  </div>

                  <div className='mt-10'>
                    <div className='flex items-center justify-center pt-5'>
                      <Link to="/login" className="hover:underline">Go back to Login</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
}