import AuthenticatedService from '../../../../services/AuthenticatedService';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const CreateComment = async (data) => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken(); 
  try {
    const response = await fetch(`${BASE_URL}/comment/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      },
      body: JSON.stringify(data)
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const UpdateComment = async (data) => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken(); 
  try {
    const response = await fetch(`${BASE_URL}/comment/${data.comment_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      },
      body: JSON.stringify(data)
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const FetchBoards = async () => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken(); 
  try {
    const response = await fetch(`${BASE_URL}/admin/board/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      }
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const FetchStatus = async () => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken(); 
  try {
    const response = await fetch(`${BASE_URL}/admin/status/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      }
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const FetchPost = async (
  checkedBoardSlugs, 
  checkedStatusIds, 
  nextPage = 1,
  dateFilter,
  startDate,
  endDate,
  searchKey = ''
) => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken(); 

  if (!dateFilter) dateFilter = '';
  if (!startDate) startDate = '';
  if (!endDate) endDate = '';

  try {
    const response = await fetch(
      `${BASE_URL}/posts/admin/?board_slugs=${checkedBoardSlugs}&status_ids=${checkedStatusIds}&page_number=${nextPage}&date_filter=${dateFilter}&start_date=${startDate}&end_date=${endDate}&search_key=${searchKey}`, 
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      }
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const FetchTotalPostCount = async () => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken(); 

  try {
    const response = await fetch(
      `${BASE_URL}/posts/total_count/`, 
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      }
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const FetchUsers = async (data) => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken(); 
  try {
    const response = await fetch(`${BASE_URL}/users/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      },
      body: JSON.stringify(data)
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const DoVote = async (post_id) => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken();
  try {
    const response = await fetch(`${BASE_URL}/vote/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      },
      body: JSON.stringify({"post_id": post_id})
    });

    // Check for the status code 204 and return a specific object
    if (response.status === 204) {
      return {
        status: 204,
        data: null,
      };
    }

    const responseData = await response.json();
    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const DeletePost = async (id) => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken();
  try {
    const response = await fetch(`${BASE_URL}/posts/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      }
    });
    
    if (!response.ok) {
      return {
        status: response.status // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const ChangeStatus = async (status_id, post_id) => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken();
  try {
    const response = await fetch(`${BASE_URL}/admin/post_status/${post_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      },
      body: JSON.stringify({"status_type_id": status_id})
    });

    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const ChangeBoard = async (board_id, post_id) => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken();
  try {
    const response = await fetch(`${BASE_URL}/posts/update-board/${post_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      },
      body: JSON.stringify({"board_id": board_id})
    });

    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const GetCommentsByPostId = async (post_id) => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken();
  try {
    const response = await fetch(`${BASE_URL}/comment/${post_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      }
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const DeletePostComment = async (id) => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken();
  try {
    const response = await fetch(`${BASE_URL}/comment/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      }
    });
    
    if (!response.ok) {
      return {
        status: response.status // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const UpdatePost = async (updated_post, post_id) => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken(); 
  try {
    const response = await fetch(`${BASE_URL}/posts/${post_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      },
      body: JSON.stringify(updated_post)
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};
