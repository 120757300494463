import { useCallback, useEffect, useState, useRef } from 'react';
import Navbar from '../../../components/Navbar';
import Status from './components/Status';
import { FetchStatusWithPosts } from './utils/api';

export default function RoadmapAdmin() {

  const scrollContainerRef = useRef(null);
  const [statusData, setStatusData] = useState([]);
  const [scrolling, setScrolling] = useState(false);


  const getStatusPostData = useCallback(async () => {
    const result = await FetchStatusWithPosts();
    // console.log(result.data)
    setStatusData(result.data);
  }, [])

  useEffect(() => {
    getStatusPostData();
  }, [getStatusPostData])

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
  
    if (!scrollContainer) return;
  
    let spacebarPressed = false;
    let mousePressed = false;
    let startX = 0;
    let startScrollLeft = 0;
  
    const handleSpacebarDown = (event) => {
      if (event.key === " ") {
        spacebarPressed = true;
      }
    };
  
    const handleSpacebarUp = (event) => {
      if (event.key === " ") {
        spacebarPressed = false;
      }
    };
  
    const handleMouseDown = (event) => {
      mousePressed = true;
      startX = event.pageX - scrollContainer.offsetLeft;
      startScrollLeft = scrollContainer.scrollLeft;
      setScrolling(true);
    };
  
    const handleMouseUp = () => {
      mousePressed = false;
      setScrolling(false);
    };
  
    const handleMouseMove = (event) => {
      if (spacebarPressed && mousePressed) {
        event.preventDefault();
        const x = event.pageX - scrollContainer.offsetLeft;
        const walk = x - startX;
        scrollContainer.scrollLeft = startScrollLeft - walk;
      }
    };
  
    window.addEventListener("keydown", handleSpacebarDown);
    window.addEventListener("keyup", handleSpacebarUp);
    scrollContainer.addEventListener("mousedown", handleMouseDown);
    scrollContainer.addEventListener("mouseup", handleMouseUp);
    scrollContainer.addEventListener("mousemove", handleMouseMove);
  
    return () => {
      window.removeEventListener("keydown", handleSpacebarDown);
      window.removeEventListener("keyup", handleSpacebarUp);
      scrollContainer.removeEventListener("mousedown", handleMouseDown);
      scrollContainer.removeEventListener("mouseup", handleMouseUp);
      scrollContainer.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);  
  
  return (
    <>
      <Navbar />
      <div className="flex flex-col mt-[60px]">
        <div
          className={`flex w-full overflow-x-auto 
            bg-orange-300 dark:bg-gray-500
            ${scrolling ? 'cursor-pointer' : ''}`}
          style={{ height: 'calc(100vh - 70px)' }}
          ref={scrollContainerRef}
        >
          <div className="flex flex-nowrap">
          {
            statusData.map((status) => (
              <Status
                key={status.status_name}
                className="flex-shrink-0"
                status_name={status.status_name}
                status_slug={status.status_slug}
                status_data={status.post_datas}
              />
            ))
          }
          </div>
        </div>
      </div>
    </>
  );
}