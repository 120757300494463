import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  IoIosAdd,
  IoMdCreate,
  IoMdArrowDropup,
  IoIosArrowBack,
} from "react-icons/io";
import { LuLoader2, LuSearchX } from "react-icons/lu";
import {
  FaChalkboard,
  FaCommentAlt
} from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import { TbArrowsSort } from "react-icons/tb";
import { FiSearch } from 'react-icons/fi';
import { MdContentCopy } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Navbar from '../../../components/Navbar';
import Filters from './components/Filters';
import CreatePost from '../CreatePost/CreatePost';
// import ImageUploader from '../CreatePost/components/ImageUploader';
import RemoveConfirmationModal from '../../../components/RemoveConfirmationModal';
import ImageViewer from '../../../components/ImageViewer';
import Comments from './components/Comments';
import DynamicDropdown from './components/DynamicDropdown';
import _ from 'lodash';

import {
  FetchBoards,
  FetchStatus,
  FetchPost,
  FetchUsers,
  CreateComment,
  DeletePost,
  DoVote,
  ChangeStatus,
  GetCommentsByPostId,
  DeletePostComment,
  ChangeBoard,
  FetchTotalPostCount
} from './utils/api';
import { autoGrowTextArea, formatDateTime } from './utils/helpers';
import UpdatePostForm from '../CreatePost/components/UpdatePostForm';
// import useDebounce from '../../../utils/UseDebounce';
// import { setDate } from 'date-fns';

export default function FeedbackAdmin() {
  const [boards, setBoards] = useState([]);
  const [checkedBoards, setCheckedBoards] = useState({});

  const [status, setStatus] = useState([]);

  const [posts, setPosts] = useState([]);
  const [postData, setPostData] = useState(null);
  const [totalPostCount, setTotalPostCount] = useState(null);
  const [isCreatePostOpen, setIsCreatePost] = useState(false);
  const [postSelected, setPostSelected] = useState(null);
  const [isPostOpen, setIsPostOpen] = useState(false);
  const [postComments, setPostComments] = useState([]);

  const [users, setUsers] = useState([]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);

  const [showCommentControls, setShowCommentControls] = useState(false);

  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [attachment, setAttachment] = useState(null);

  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [deleteValue, setDeleteValue] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCommentDeleteModalOpen, setIsCommentDeleteModalOpen] = useState(false);
  const [commentDeleteId, setCommentDeleteId] = useState(null);

  const filterRef = useRef(null);
  const sortRef = useRef(null);
  const postListRef = useRef(null);

  const [page, setPage] = useState(1);
  const [hasMorePosts, setHasMorePosts] = useState(true);

  const [commentValues, setCommentValues] = useState({});
  const [commentValidationErrors, setCommentValidationErrors] = useState({});
  const [parentCommentId, setParentCommentId] = useState(null);

  const [isEditing, setIsEditing] = useState(false);

  const [dateFilter, setDateFilter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [hasNavigated, setHasNavigated] = useState(false);
  const [isPostListLoading, setIsPostListLoading] = useState(true);

  const fetchVersion = useRef(0);  // Track the fetch requests

  const toggleFilterDropdown = () => setIsFilterOpen(!isFilterOpen);
  const toggleSortDropdown = () => setIsSortOpen(!isSortOpen);

  const { board_slug, post_slug } = useParams();

  const location = useLocation();
  const urlParts = location.pathname.split('/');

  const searchParams = new URLSearchParams(location.search);
  const statuses = searchParams.get('status');
  const dateFilterType = searchParams.get('date_filter');
  const dateFrom = searchParams.get('date_from');
  const dateTo = searchParams.get('date_to');

  useEffect(() => {
    setStartDate(dateFrom);
    setEndDate(dateTo);
    setDateFilter(dateFilterType);
  }, [dateFrom, dateTo, dateFilterType]);

  useEffect(() => {
    
    const checkIfMobile = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setIsSortOpen(false);
      }
    };

    checkIfMobile();

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', checkIfMobile);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const showCreatePostModal = () => {
    setShowCommentControls(false);
    setIsCreatePost(true);
  };

  const hideCreatePostModal = () => {
    setIsCreatePost(false);
  };

  useEffect(() => {
    setHasNavigated(false);
    if (urlParts[2] === 'feedback' && urlParts.length > 3) {
      setHasNavigated(true);
    }
  }, [urlParts]);

  useEffect(() => {
   
    const fetchData = async () => {
      let initialCheckedStates = {};

      const totalPostCountData = await FetchTotalPostCount();
      setTotalPostCount(totalPostCountData.data);

      const boardsResponse = await FetchBoards();
      if (Array.isArray(boardsResponse.data)) {
        setBoards(boardsResponse.data);
        initialCheckedStates = boardsResponse.data.reduce((acc, board) => ({
          ...acc,
          [board.slug]: true,
        }), {});
        // initialCheckedStates[boardsResponse.data[0].slug] = true;
        // console.log(initialCheckedStates)
        setCheckedBoards(initialCheckedStates);
      } else {
        console.error('FetchBoards did not return an array');
      }

      const statusResponse = await FetchStatus();
      if (Array.isArray(statusResponse.data)) {
        const updatedStatus = statusResponse.data.map(s => (
          { ...s, is_checked: s.is_checked || false }
        ));
        // console.log(updatedStatus)
        // console.log(statuses)
        if (statuses) {
          const parts = statuses.split('_');
          const updatedStatusWithCheck = updatedStatus.map(status => {
            if (parts.includes(status.status_slug)) {
              return { ...status, is_checked: true };
            }
            return status;
          });
          setStatus(updatedStatusWithCheck);
        } else {
          setStatus(updatedStatus);
        }
      } else {
        console.error('FetchStatus did not return an array');
      }
      await new Promise(resolve => setTimeout(resolve, 0));

      const usersResponse = await FetchUsers();
      if (Array.isArray(usersResponse.data)) {
        setUsers(usersResponse.data);
      } else {
        console.error('FetchUsers did not return an array');
      }
    };

    fetchData();
  }, [statuses]);

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && hasMorePosts) {
      fetchMorePosts();
    }
  };

  const fetchMorePosts = async () => {
    if (isLoading) return;
    setIsLoading(true);
    
    // console.log(dateFilter)
    // console.log(startDate)
    // console.log(endDate)
    // console.log(page)
    const nextPage = page + 1;
    // console.log(nextPage)
    const checkedBoardSlugs = Object.keys(checkedBoards).filter(slug => checkedBoards[slug]);
    const checkedStatusIds = status.filter(s => s.is_checked).map(s => s.id);

    try {
      const postDatas = await FetchPost(
        checkedBoardSlugs,
        checkedStatusIds,
        nextPage,
        dateFilter,
        startDate,
        endDate,
        searchKey
      );
      if (postDatas.data.length > 0) {
        setPosts(prevPosts => [...prevPosts, ...postDatas.data]);
        setPage(nextPage);
      } else {
        setHasMorePosts(false);
      }
    } catch (error) {
      console.error('Error fetching more posts:', error);
      toast.error("Failed to load more posts");
    } finally {
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  const handlePostDetails = (post) => {
    navigate(`/admin/feedback/${post.board_slug}/p/${post.slug}`);
  };

  useEffect(() => {
    // console.log('URL changed:', location.pathname);
    // console.log('Board slug:', board_slug);
    // console.log('Post slug:', post_slug);
    const matchingPost = posts.filter(post => post !== null).find(
      post => post.board_slug === board_slug && post.slug === post_slug
    );
    if (matchingPost) {
      setShowCommentControls(false);
      loadCommentsByPostId(matchingPost.id);
      setPostSelected(matchingPost.id);
      setIsPostOpen(true);
      setCommentValidationErrors({});
      setIsEditing(false);
      setPostData(matchingPost)
    }
  }, [posts, board_slug, post_slug]);


  const handleDateFilter = (
    dateFilterParams,
    startDateParams,
    endDateParams,
  ) => {
    
    const currentUrl = new URL(location.pathname, window.location.origin);
    if (dateFilterParams && startDateParams && endDateParams) {
      currentUrl.searchParams.set('date_filter', dateFilterParams);
      currentUrl.searchParams.set('date_from', startDateParams);
      currentUrl.searchParams.set('date_to', endDateParams);
      
      const newSearchParams = currentUrl.searchParams.toString();
  
      navigate({
        pathname: location.pathname,
        search: newSearchParams,
      });
    } else {
      const oldSearchParams = new URLSearchParams(location.search);
      const newSearchParams = new URLSearchParams();

      for (const [key, value] of oldSearchParams) {
        if (key!== 'date_filter' && key!== 'date_from' && key!== 'date_to') {
          newSearchParams.set(key, value);
        }
      }

      navigate({
        pathname: location.pathname,
        search: newSearchParams.toString(),
      });
    }
  }

  const debouncedSearchKey = useRef(
    _.debounce((value) => setSearchKey(value), 500)
  ).current;

  const handleSearchFilter = (searchValue) => {
    // setSearchKey(searchValue);
    debouncedSearchKey(searchValue);
  }

  const fetchPostWithFilter = useCallback(async () => {
    // console.log(fetchVersion.current)
    const currentVersion = ++fetchVersion.current;
    setIsPostListLoading(true);
    setPosts([]);
    setPage(0);
    // setPostData(null);

    const checkedBoardSlugs = Object.keys(checkedBoards).filter(slug => checkedBoards[slug]);
    const checkedStatusIds = status.filter(s => s.is_checked).map(s => s.id);
    
    if (checkedBoardSlugs.length === 0) return;
    if (checkedStatusIds.length === 0) return;
    // console.log(checkedBoardSlugs)
    // console.log(searchKey)
    // return;
    try {
      const postDatas = await FetchPost(
        checkedBoardSlugs,
        checkedStatusIds,
        1,
        dateFilter,
        startDate,
        endDate,
        searchKey
      );

      if (currentVersion === fetchVersion.current && postDatas.data.length > 0) {
        setPosts(postDatas.data);
        // console.log(hasNavigated)
        if (postDatas.data[0].board_slug && postDatas.data[0].slug && !hasNavigated) {
          navigateRef.current(`/admin/feedback/${postDatas.data[0].board_slug}/p/${postDatas.data[0].slug}`);
          setHasNavigated(true);
        }
        setPage(1);
      } else {
        setHasMorePosts(false);
      }
    } catch (error) {
      console.error('Error fetching filtered posts:', error);
      toast.error("Failed to load filtered posts");
    } finally {
      setIsLoading(false);
      setIsPostListLoading(false);
    }
  }, [ checkedBoards, status, dateFilter, startDate, endDate, searchKey, hasNavigated]);

  useEffect(() => {
    fetchPostWithFilter();
  }, [fetchPostWithFilter]);


  // insert new post
  const insertPostData = async(board_id) => {
    const checkedBoardSlugs = Object.keys(checkedBoards).filter(slug => checkedBoards[slug]);
    const checkedStatusIds = status.filter(s => s.is_checked).map(s => s.id);

    const postDatas = await FetchPost(checkedBoardSlugs, checkedStatusIds);
    setPosts(postDatas.data);
    setIsEditing(false);

    const totalPostCountData = await FetchTotalPostCount();
    setTotalPostCount(totalPostCountData.data);

    setBoards(prevBoards => prevBoards.map(board => {
      if (board.id === board_id) {
        // Update the posts count for the matching board
        const newPostsCount = parseInt(board.posts_count) + 1;
        return {
          ...board,
          posts_count: parseInt(newPostsCount) > 99 ? '99+' : newPostsCount
        };
      }
      // Return the board unchanged if it's not the one we're updating
      return board;
    }));
  }

  const updatePostData = async (data) => {
    // console.log(data)
    // console.log(posts)
    // console.log(postData)
    setPosts(prevPosts => {
      const postIndex = prevPosts.findIndex(post => post.id === data.id);
      if (postIndex !== -1) {
        const updatedPosts = [...prevPosts];
        updatedPosts[postIndex] = {
          ...updatedPosts[postIndex],
          title: data.title,
          content: data.content,
          is_private: data.is_private,
          attachments: data.images
        };
        return updatedPosts;
      }
      return prevPosts;
    });

    setPostData(prevPostData => {
      if (prevPostData.id === data.id) {
        return {
          ...prevPostData,
          title: data.title,
          content: data.content,
          is_private: data.is_private,
          attachments: data.images
        };
      }
      return prevPostData;
    });
    setIsEditing(false);
  }

  const toggleStatusChecked = (status_name) => {
    // console.log(status_name)
    setStatus(prevStatus => prevStatus.map(
      s => s.status_name === status_name ? { ...s, is_checked: !s.is_checked } : s
    ));
  };

  const toggleBoardChecked = (boardSlug) => {
    setCheckedBoards(prev => ({
      ...prev,
      [boardSlug]: !prev[boardSlug],
    }));
  };

  const handleVote = (id) => {
    setPosts(prevPosts => prevPosts.map(post => {
      if (post.id === id) {
        const updatedPost = {
          ...post,
          user_voted: !post.user_voted,
          no_of_vote: post.user_voted ? post.no_of_vote - 1 : post.no_of_vote + 1
        };
  
        if (postData && postData.id === id) {
          setPostData(updatedPost);
          createVote(id);
        }
  
        return updatedPost;
      }
      return post;
    }));
  }

  const createVote = async (post_id) => {
    const res = await DoVote(post_id);
    if (res.status === 200) {
      toast.success("Vote added");
    } else if (res.status === 204) {
      toast.error("Vote removed");
    } else {
      toast.error("Something went wrong");
    }
  }

  const loadCommentsByPostId = async (post_id) => {
    const res = await GetCommentsByPostId(post_id);
    if (res.status === 200) {
      setPostComments(res.data);
    } else {
      toast.error("Something goes wrong")
    }
  }

  const handlePostRemove = () => {
    setDeleteValue("post");
    setIsDeleteModalOpen(true);
  }

  const deleteConfirm = async () => {
    const res = await DeletePost(postData.id);
    if (res.status === 204) {
      setIsDeleteModalOpen(false);
      removePost(postData.id, postData.board_id);
    }
  }

  const removePost = async (postId, board_id) => {
    // Update the boards array with the new posts count for the specific board
    setBoards(prevBoards => prevBoards.map(board => {
      if (board.id === board_id) {
        // Update the posts count for the matching board
        const newPostsCount = parseInt(board.posts_count) - 1;
        return {
          ...board,
          posts_count: parseInt(newPostsCount) > 99 ? '99+' : newPostsCount
        };
      }
      // Return the board unchanged if it's not the one we're updating
      return board;
    }));

    // Filter out the post that is being deleted
    const updatedPosts = posts.filter(post => post.id !== postId);
    setPosts(updatedPosts);

    // Reset postData if the current post is being deleted
    setPostData(null);

    const totalPostCountData = await FetchTotalPostCount();
    setTotalPostCount(totalPostCountData.data);
  };

  const handleBoardChange = async (board_id, board_name, post_id) => {
    const res = await ChangeBoard(board_id, post_id);
    if (res.status === 200) {

      setPosts(prevPosts => prevPosts.map(post => {
        if (post.id === post_id) {
          const updatedPost = {
            ...post,
            board_id: board_id,
            no_of_comment: post.no_of_comment + 1
          };
    
          if (postData && postData.id === post_id) {
            setPostData(updatedPost);
          }
    
          return updatedPost;
        }
        return post;
      }));

      toast.success(`Post moved to ${board_name}`)
      loadCommentsByPostId(post_id);
    } else {
      toast.error("Something goes wrong")
    }
  }
  
  const handleStatusChange = async(status_id, status_name, post_id) => {
    const res = await ChangeStatus(status_id, post_id);
    if (res.status === 201) {

      setPosts(prevPosts => prevPosts.map(post => {
        if (post.id === post_id) {

          const updatedStatus = status.find(status_one => status_one.id === status_id && status_one.is_checked);
          console.log(updatedStatus)
          if (updatedStatus) {
            const updatedPost = {
              ...post,
              status_id: status_id,
              status_name: status_name,
              no_of_comment: post.no_of_comment + 1
            };

            console.log(postData.id);
            console.log(post_id);
            if (postData && postData.id === post_id) {
              setPostData(updatedPost);
            }
      
            return updatedPost;
          } else {
            return null;
          }
        }
        return post;
      }));

      toast.success("Status changed")
      loadCommentsByPostId(post_id);
    } else {
      toast.error("Something goes wrong")
    }
  }

  const showAttachment = (value) => {
    setAttachment(value);
    setIsImageViewerOpen(true);
  }

  const hideImageViewer = () => {
    setIsImageViewerOpen(false);
  }

  const handleCommentRemove = (id) => {
    setDeleteValue("comment");
    setIsCommentDeleteModalOpen(true);
    setCommentDeleteId(id);
  }

  const deleteCommentConfirm = async (id) => {
    const res = await DeletePostComment(id);
    if (res.status === 204) {
      setIsCommentDeleteModalOpen(false);
      removeComment(id);
      toast.success("Comment deleted");
    }
  }

  const removeComment = (id, comments = postComments) => {
    // Helper function to recursively remove a comment by id
    const recursivelyRemoveComment = (comments, id) => {
      return comments.reduce((acc, comment) => {
        if (comment.comment_id === id) {
          // Skip adding this comment to the accumulator
          return acc;
        } else {
          // Include this comment, but first handle its child comments if they exist
          if (comment.child_comments && comment.child_comments.length > 0) {
            comment = {
              ...comment,
              child_comments: recursivelyRemoveComment(comment.child_comments, id)
            };
          }
          return [...acc, comment];
        }
      }, []);
    };
  
    // Start the recursive removal from the top-level comments
    const updatedComments = recursivelyRemoveComment(comments, id);
  
    // Update the state with the filtered comments
    setPostComments(updatedComments);
  
    // Update the post count for comments
    setPosts(prevPosts => prevPosts.map(post => {
      if (post.id === postData.id) {
        const updatedPost = {
          ...post,
          no_of_comment: post.no_of_comment - 1
        };
        return updatedPost;
      }
      return post;
    }));
  };  

  const saveComment = async (commentData) => {
    const errors = {};
  
    // Check if the comment text is provided
    if (!commentData.comment) {
      errors.comment = 'You should leave your comment first!';
      setCommentValidationErrors(errors);
      return;
    }
  
    const fullCommentData = {
      post_id: postData.id,
      comment: commentData.comment,
      additional_note: null,
      parent_comment_id: commentData.parent_comment_id || null,
      images: images
    };
  
    try {
      const res = await CreateComment(fullCommentData);
      if (res.status === 201) {
        toast.success('Comment saved successfully');
        // Clear the main comment box only if it's a main comment being saved
        if (!commentData.parent_comment_id) {
          setCommentValues({ comment: '' });
        }
        setParentCommentId(null);
        setImages([]);
        loadCommentsByPostId(postData.id);
  
        // Update the post's comment count
        setPosts(prevPosts => prevPosts.map(post => {
          if (post.id === postData.id) {
            const updatedPost = {
              ...post,
              no_of_comment: post.no_of_comment + 1
            };
            return updatedPost;
          }
          return post;
        }));
      }
    } catch (error) {
      console.error('Failed to save comment:', error);
      toast.error('Error saving comment');
    }
  };

  const togglePostEdit = () => {
    setIsEditing(!isEditing);
    // setEditedPost(postData.content);
  };

  const setBackOnMobile = () => {
    setIsPostOpen(false);
    navigate('/admin/feedback/');
  }

  const handleSetIsSidebarOpen = (value) => {
    // console.log(value)
    setIsSidebarOpen(value);
  }

  return (  
    <>
      <Navbar />
      <div className="flex mt-[60px] overflow-y-hidden z-10" style={{ height: 'calc(100vh - 60px)' }}>
        
        <Filters
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={handleSetIsSidebarOpen}
          boards={boards}
          checkedBoards={checkedBoards}
          toggleBoardChecked={toggleBoardChecked}
          toggleStatusChecked={toggleStatusChecked}
          status={status}
          handleFilter={handleDateFilter}
        />

        {!isPostListLoading && totalPostCount === 0 && (
          <div className="flex flex-col justify-center items-center h-screen 
            w-full">
            <FaChalkboard size={70} className='text-orange-500' />

            <div className='font-bold mt-2 text-xl'>
              🎉 Congratulation, a new board
            </div>
            <div className='text-center mt-3'>
              Create your first few posts to lead the example
            </div>
            <button
              type='button'
              className='text-orange-500 px-3 py-2 rounded-md 
                border border-orange-500 cursor-pointer flex mt-3
                hover:bg-orange-500 hover:text-white'
              onClick={showCreatePostModal}
            >
              <IoIosAdd size={25} /> CREATE POST
            </button>
          </div>
        )}
        
        {totalPostCount > 0 && (
          <div className={`
          ${isPostOpen ? 'hidden md:flex' : 'flex'} flex-col w-full 
          ${isSidebarOpen ? 'md:w-3/12' : 'md:w-4/12'}  overflow-y-auto border
          border-r-1 dark:bg-gray-800 dark:text-white`}>
            <div className='flex p-2'>
              <div className='w-10/12 relative z-0'>
                <FiSearch
                  size={20}
                  className='absolute left-3 top-1/2 transform 
                  -translate-y-1/2 text-gray-400 z-0'
                />
                <input
                  type="text"
                  placeholder="Search by title, description"
                  className='pl-10 pr-3 py-2 border-slate-300 bg-gray-100
                  focus:border-orange-500 focus:outline-none 
                  focus:bg-white w-full rounded-md 
                  dark:bg-gray-800
                  dark:focus:bg-gray-700
                  dark:hover:bg-gray-700
                  border-solid border-2 z-0 hover:bg-white'
                  // value={searchKey}
                  onChange={(e) => handleSearchFilter(e.target.value)}
                />
              
              </div>
              <div className='w-1/12'>
                <div className='justify-center items-center text-center pt-1.5 ml-2
                border-2 border-slate-300 rounded-md w-[45px] h-full 
                bg-gray-100 cursor-pointer hover:border-orange-500
                dark:bg-gray-800'
                  onClick={() => showCreatePostModal()}
                >
                  <IoMdCreate
                    size={25}
                    className='cursor-pointer text-slate-700 w-full
                    hover:text-orange-500 dark:text-gray-100'
                  />
                </div>
              </div>
            </div>

            <div className='flex w-full'>

              <div
                ref={filterRef}
                className='flex w-6/12 cursor-pointer ml-3 mb-2'
                onClick={toggleFilterDropdown}
              >
                <div className='flex hover:text-orange-600 hover:bg-gray-100
                p-1 pr-3 rounded-sm
                dark:hover:bg-gray-700'>
                  <IoFilter
                    size={20}
                    className='mr-1'
                  />
                  Default
                </div>
                {isFilterOpen && (
                  <div className="absolute mt-8 w-48 bg-white dark:bg-gray-800 
                  shadow-md
                  rounded-md">
                    <div
                      className='p-2 hover:bg-gray-100 dark:hover:bg-gray-700'
                    >
                      Option 1
                    </div>
                    <div
                      className='p-2 hover:bg-gray-100 dark:hover:bg-gray-700'
                    >
                      Option 2
                    </div>
                  </div>
                )}
              </div>
              <div
                ref={sortRef}
                className='flex w-6/12 cursor-pointer ml-3 mb-2'
                onClick={toggleSortDropdown}
              >
                <div className='flex hover:text-orange-600 hover:bg-gray-100
                p-1 pr-3 rounded-sm dark:hover:bg-gray-700'>
                  <TbArrowsSort
                    size={20}
                    className='hover:text-orange-500 mr-1'
                  />
                  Trending
                </div>
                {isSortOpen && (
                  <div className="absolute mt-8 w-48 bg-white shadow-md
                  rounded-md dark:bg-gray-800">
                    <div
                      className='p-2 hover:bg-gray-100 dark:hover:bg-gray-700'
                    >
                      Option 1
                    </div>
                    <div
                      className='p-2 hover:bg-gray-100 dark:hover:bg-gray-700'
                    >
                      Option 2
                    </div>
                  </div>
                )}
              </div>
            
            </div>
          

            {!isPostListLoading && totalPostCount > 0 && posts.length === 0 && (
              <div className="flex flex-col justify-center items-center h-screen 
              w-full">

                <div className='flex flex-col justify-center items-center mt-2 text-gray-500'>
                  <LuSearchX size={45} className="text-gray-500" />
                  <div className='mt-2'>
                    No post found with your search.
                  </div>
                </div>
              </div>
            )}

            {isPostListLoading && (
              <div className="flex flex-col justify-center items-center h-screen 
              w-full">
                <div className='text-gray-500'>
                  <LuLoader2 size={45} className="text-orange-500 animate-spin" />
                  <div>Loading...</div>
                </div>
              </div>
            )}

            <div className='w-full overflow-y-auto' ref={postListRef} onScroll={handleScroll}>
              {!isPostListLoading && posts.filter(post => post !== null).map((post, index) => (
                <div key={index} className={`flex flex-col border-b-1 p-3 
                md:p-1 cursor-pointer
                ${postSelected === post.id
                    ? 'bg-orange-50 border-l-4 border-orange-500 dark:bg-gray-700'
                    : 'bg-white border dark:bg-gray-800'}`
                }
                  onClick={() => handlePostDetails(post)}
                >
                  <div className='flex'>
                    <div className="flex flex-col">
                      <div className='px-4 pt-2 md:h-9 font-bold text-gray-600 
                      dark:text-gray-200 overflow-y-hidden'>
                        {post.title}
                      </div>
                      <div
                        className='px-4 md:h-12 text-gray-600 dark:text-gray-300
                        overflow-y-hidden'
                      >
                        {
                          post.content.length > 80 ? post.content.slice(0, 80) + '...' : post.content
                        }
                      </div>
                    </div>
                  </div>
                  <div className="flex text-gray-500 px-2 py-1 justify-between">
                    <div className='flex'>
                      <IoMdArrowDropup
                        size={30}
                        className={`
                        ${post.user_voted ? 'text-orange-500' : 'text-gray-500 dark:text-gray-300'}`
                        }
                      />
                      <span className='mt-1 text-sm dark:text-gray-300'>
                        {post.no_of_vote}
                      </span>

                      <FaCommentAlt
                        size={12}
                        className='mt-2 ml-5 mr-2 dark:text-gray-300'
                      />
                      <span className='mt-1 text-sm dark:text-gray-300'>
                        {post.no_of_comment}
                      </span>
                    </div>
                    
                    {post.status_name && (
                      <div className={`mt-1 px-2 py-1 rounded text-sm 
                      text-white bg-orange-600 
                      ${postSelected === post.id ? 'dark:bg-slate-900' : 'dark:bg-slate-700'}`
                      }
                      >
                        {post.status_name}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
         )}

        {!isPostListLoading && totalPostCount > 0 && (
        <div className={`
          ${isPostOpen ? 'flex' : 'hidden'} md:flex flex-col w-full 
          border border-r-1 
          bg-gray-100 dark:bg-gray-700 p-2 overflow-y-auto
          ${isSidebarOpen ? 'md:w-7/12' : 'md:w-8/12'}`}>
          <div className='w-full rounded-md bg-white dark:bg-gray-800 border-slate-400'>
            {postData && (
              <>
                <div className='md:hidden p-3 flex dark:text-gray-200 cursor-pointer'
                  onClick={() => setBackOnMobile()}
                >
                  <IoIosArrowBack size={25} /> Back to list
                </div>
                <div className='flex border-b border-gray-300 w-full p-2'>
                  <div className='flex flex-col items-center justify-center'>
                    <IoMdArrowDropup
                      size={50}
                      className={`cursor-pointer mt-[-8px]
                        ${postData.user_voted ? 'text-orange-500' : 'text-gray-500 dark:text-gray-200'}`}
                      onClick={() => handleVote(postData.id)}
                    />
                    <div className='mt-[-15px] dark:text-gray-200'>
                      {postData.no_of_vote}
                    </div>
                  </div>

                  <div className="w-10/12 mt-2 text-xl md:text-2xl ml-2 font-semibold 
                    text-gray-700 dark:text-gray-200">
                    {postData.title}
                  </div>
                </div>
                
                <div className="md:flex">
                  {isEditing && (
                    <div
                      className='md:flex flex-col p-5 w-full md:w-2/3 overflow-y-auto'
                      style={{ height: '81vh' }}
                    >
                      <UpdatePostForm
                        editCancel={togglePostEdit}
                        postData={postData}
                        updatePostData={updatePostData}
                      />
                    </div>
                  )}
                  
                  { !isEditing && (
                  <div className="md:flex flex-col p-5 w-full md:w-2/3 overflow-y-auto"
                    style={{ height: '81vh' }}>
                      <div className="text-gray-700 dark:text-gray-200">{ postData.content }</div>
                    <div className='mt-3 mb-3 mr-3 flex'>
                      {postData.attachments != null && postData.attachments.map((attachment, index) => (
                        <img
                          key={index}
                          src={attachment}
                          alt=""
                          className='w-[100px] cursor-pointer'
                          onClick={() => showAttachment(attachment)}
                        />
                      ))}
                    </div>
                    <div className='mt-5 text-sm text-gray-700 dark:text-gray-200 font-semibold'>
                      <span>Created by {postData.owner_full_name}</span>
                      <span className='ml-5'>{formatDateTime(postData.created_at)}</span>
                    </div>
                    <div className='flex mt-2 text-sm text-gray-700 dark:text-gray-200 justify-between'>
                      <div className='cursor-pointer hover:text-gray-800 dark:hover:text-gray-100 pr-2' onClick={togglePostEdit}>Edit Post</div>
                      <div className='cursor-pointer px-2 text-red-500 hover:text-red-600' onClick={handlePostRemove}>Delete Post</div>
                    </div>

                    <div className='mt-10'>
                      <form onSubmit={(e) => { 
                        e.preventDefault();
                        saveComment({ comment: commentValues.comment, parent_comment_id: null });
                      }}>
                        <textarea
                          id='comment'
                          name="comment"
                          className={`w-full px-3 py-2 border-solid border-2 
                            ${commentValidationErrors.comment ? 'border-red-500' : 'border-slate-300'}
                            rounded-md focus:border-orange-500 
                            focus:outline-none bg-gray-50
                            dark:bg-gray-800 dark:text-gray-200
                            focus:bg-white dark:focus:bg-gray-700 overflow-hidden 
                            resize-none`}
                          placeholder="Leave your comment here..."
                          onInput={autoGrowTextArea}
                          style={{ minHeight: '28px' }}
                          rows={2}
                          onFocus={() => setShowCommentControls(true)}
                          value={commentValues.comment}
                          onChange={(e) => setCommentValues({ ...commentValues, comment: e.target.value })}
                        ></textarea>

                        {commentValidationErrors.comment && <p className="text-red-500">{commentValidationErrors.comment}</p>}
                        
                        {showCommentControls && (
                          <div className="flex justify-between pt-3">
                            <div>
                              {/* <ImageUploader
                                images={images}
                                setImages={setImages}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                BASE_URL={BASE_URL}
                              /> */}
                              <button
                                type="button"
                                className='dark:text-gray-300 px-3'
                                onClick={(e) => setShowCommentControls(false)}
                              >
                                Cancel
                              </button>
                            </div>
                            <div>
                              <button
                                type="submit"
                                className='bg-orange-500 hover:bg-orange-600
                                  text-white rounded-md
                                  px-5 py-2'
                              >
                                Save
                              </button>
                            </div>
                          </div>
                      )}
                      </form>
                    </div>
                    <Comments
                      postComments={postComments}
                      handleCommentRemove={handleCommentRemove}
                      parentCommentId={parentCommentId}
                      setParentCommentId={setParentCommentId}
                      // commentValues={commentValues}
                      // setCommentValues={setCommentValues}
                      saveComment={saveComment}
                    />
                  </div>
                  )}
                  <div className='flex flex-col p-3 w-full md:w-1/3 border-l border-gray-300'>
                    <div className='text-gray-600 dark:text-gray-300 text-xl font-semibold'>Details</div>
                    <div className='mt-3'>
                      <div className='text-gray-700 dark:text-gray-200'>Public link</div>
                      <div className='flex text-gray-500 dark:text-gray-300 mt-1'>
                        <input
                          id="publicLink"
                          type="text"
                          className='w-10/12 dark:bg-gray-600'
                          value={window.location.origin + '/' + postData.board_slug + /p/ + postData.slug}
                          readOnly="readOnly"
                        />
                        <MdContentCopy
                          className='mt-1 ml-2 hover:cursor-pointer'
                          onClick={() => {
                            navigator.clipboard.writeText(document.getElementById('publicLink').value)
                              .then(() => {
                                toast.success("Copied to clipboard!")
                              })
                            .catch(err => console.error('Error copying text: ', err));
                          }}
                        />
                      </div>
                    </div>
                    <div className='mt-3'>
                      <div className='text-gray-700 dark:text-gray-200'>Board</div>
                      <div className='text-gray-500 dark:text-gray-300 mt-1'>
                        <DynamicDropdown
                          items={boards}
                          valueKey="id"
                          labelKey="title"
                          initialValue={postData.board_id}
                          onChange={(data) => handleBoardChange(data.value, data.label, postData.id)}
                        />
                      </div>
                    </div>
                    <div className='mt-3'>
                      <div className='text-gray-700 dark:text-gray-200'>Status</div>
                      <div className='text-gray-500 dark:text-gray-300 mt-1'>
                        <DynamicDropdown
                          items={status}
                          valueKey="id"
                          labelKey="status_name"
                          initialValue={postData.status_id}
                          onChange={(data) => handleStatusChange(data.value, data.label, postData.id)}
                        />
                      </div>
                    </div>
                    <div className='mt-3'>
                      <div className='text-gray-700 dark:text-gray-200'>Owner</div>
                      <div className='text-gray-500 dark:text-gray-300 mt-1'>
                        {postData.owner_full_name && (
                          postData.owner_full_name
                        )}
                        {!postData.owner_full_name && (
                          '--'
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        )}
      </div>

      <CreatePost
        isOpen={isCreatePostOpen}
        onClose={hideCreatePostModal}
        boards={boards}
        users={users}
        insertPostData={insertPostData}
      />

      <ImageViewer
        isOpen={isImageViewerOpen}
        onClose={hideImageViewer}
        value={attachment}
      />
      
      <RemoveConfirmationModal
        id={null}
        value={deleteValue}
        isOpen={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={deleteConfirm}
      />

      <RemoveConfirmationModal
        id={commentDeleteId}
        value={deleteValue}
        isOpen={isCommentDeleteModalOpen}
        onCancel={() => setIsCommentDeleteModalOpen(false)}
        onConfirm={deleteCommentConfirm}
      />

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
