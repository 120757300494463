import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 'last_activity',
}

export const filterSortSlice = createSlice({
  name: 'filterSort',
  initialState,
  reducers: {
    updateFilterSort: (state, action) => {
      // console.log(action.payload)
      state.value = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateFilterSort } = filterSortSlice.actions

export default filterSortSlice.reducer