import AuthenticatedService from '../../../services/AuthenticatedService';

const SetTheme = async(board_id, theme_color) => {
	const BASE_URL = process.env.REACT_APP_BASE_URL;
  const data = {
    "theme_color": theme_color,
  }

  const token = AuthenticatedService.getToken(); 
  try {
    const response = await fetch(`${BASE_URL}/admin/board/theme/${board_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      },
      body: JSON.stringify(data)
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
		  // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
		throw new Error(error.message);
  }
}

export default SetTheme