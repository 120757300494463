import { FaCommentAlt } from "react-icons/fa";
import { IoMdArrowDropup } from "react-icons/io";
import { Link } from "react-router-dom";

export default function Status({ status_name, status_slug, status_data }) {
  return (
    <div
      className="w-72 bg-gray-100 dark:bg-gray-600 overflow-y-auto text-center mx-3 my-5
        rounded-2xl"
    >
      <div>
        <div
          className="bg-white dark:bg-gray-800 border 
            border-gray-50 dark:border-gray-500
            font-semibold 
            rounded-t-2xl py-2 text-gray-700 dark:text-gray-200"
        >
          {status_name}
        </div>
        {
          status_data.map((data, index) => (
            <div
              key={index}
              className="m-3 p-3 bg-white dark:bg-gray-700 text-start 
                rounded-2xl shadow-md"
            >
              <Link
                to={`/admin/feedback/${data.board_slug}/p/${data.post_slug}?status=${status_slug}`}
              >
                <div className="text-gray-700 dark:text-gray-100">
                  {data.post_title}
                </div>
                <div className="py-2 px-5 rounded-2xl 
                  bg-gray-100 dark:bg-gray-500 dark:text-gray-100
                  text-xs mt-3">
                  {data.board_name}
                </div>
                <div className="flex text-gray-500 px-2 py-1 justify-between">
                  <div className='flex'>
                    <IoMdArrowDropup
                      size={30}
                      className='text-gray-500 dark:text-gray-300'
                    />
                    <span className='mt-1 text-sm dark:text-gray-300'>
                      {data.no_of_vote}
                    </span>

                    <FaCommentAlt
                      size={12}
                      className='mt-2 ml-5 mr-2 dark:text-gray-300'
                    />
                    <span className='mt-1 text-sm dark:text-gray-300'>
                      {data.no_of_comment}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          ))
        }
      </div>
    </div>
  );
}