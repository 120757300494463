import AuthenticatedService from '../../../../services/AuthenticatedService';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const FetchStatusWithPosts = async () => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken(); 
  try {
    const response = await fetch(`${BASE_URL}/roadmap/status-posts/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      }
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};