const VerifyCode = async(email, code) => {
	const BASE_URL = process.env.REACT_APP_BASE_URL;
  const data = {
    "user_id": email,
    "otp": code,
  }
  try {
    const response = await fetch(`${BASE_URL}/signup/verify/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' // Ensure you set the content type header
      },
      body: JSON.stringify(data)
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
		  // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
		throw new Error(error.message);
  }
}

export default VerifyCode