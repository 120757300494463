import React from 'react';

const DynamicDropdown = ({ items, valueKey, labelKey, initialValue, onChange }) => {
  const handleChange = (e) => {
    const selectedValue = e.target.value;
    const selectedItem = items.find(item => item[valueKey] === selectedValue);
    onChange({ value: selectedValue, label: selectedItem[labelKey] });
  };

  return (
    <select onChange={handleChange} value={initialValue} className='dark:bg-gray-700 p-1 w-full'>
      {items.map((item) => (
        <option key={item[valueKey]} value={item[valueKey]}>
          {item[labelKey]}
        </option>
      ))}
    </select>
  );
};

export default DynamicDropdown;
