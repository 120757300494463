import React from 'react';
import Comment from './Comment';

const Comments = ({ postComments, handleCommentRemove, parentCommentId, setParentCommentId, saveComment }) => {
  return (
    <ul>
      {postComments.length > 0 && postComments.map((postComment, index) => (
        <Comment
          key={postComment.comment_id + index}
          postComment={postComment}
          handleCommentRemove={handleCommentRemove}
          topLevelParentId={parentCommentId}
          setTopLevelParentId={setParentCommentId}
          saveComment={saveComment}
        />
      ))}
    </ul>
  );
};

export default Comments;
