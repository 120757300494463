import React from 'react';
import ImageUploader from './ImageUploader';

const PostForm = (
  {
    handleChange,
    handleContentEditableChange,
    values,
    validationErrors,
    images,
    setImages,
    isLoading,
    setIsLoading,
    BASE_URL,
    width
  }) => (
  <div className={`w-full 
    ${width === 'full' ? 'md:w-full' : 'md:w-1/2 border-r border-gray-300'}  
    p-5 overflow-y-auto h-96  dark:bg-gray-800`}
    style={{ height: width === 'full' ? '65vh' : 'auto' }}
  >
    <form action="">
      <div className="flex flex-wrap mb-5">
        <label htmlFor="title" className={`${validationErrors.title ?
          'text-red-500' : 'text-gray-800 dark:text-gray-200'}`}>
          Post title
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={values.title}
          className={`w-full px-3 py-2 border-solid border-2 
            ${validationErrors.title ? 'border-red-500' : 'border-slate-300'} 
            rounded-md focus:border-orange-500 focus:outline-none
            dark:bg-gray-800 dark:focus:bg-gray-700 dark:text-gray-200`}
          onChange={handleChange}
        />
        {validationErrors.title && <p className="text-red-500">
          {validationErrors.title}</p>}
      </div>

      <div className="flex flex-wrap mb-5">
        <label htmlFor="description" className={`${validationErrors.description ?
          'text-red-500' : 'text-gray-800 dark:text-gray-200'}`}>
          Description
        </label>
        <textarea
          id='description'
          name="description"
          className={`w-full px-3 py-2 border-solid border-2 border-slate-300
            rounded-md focus:border-orange-500 focus:outline-none h-40
            overflow-y-auto
            dark:bg-gray-800 dark:focus:bg-gray-700 dark:text-gray-200`}
          onChange={handleContentEditableChange}
          value={values.description}
        />
        {/* {validationErrors.description && <p className="text-red-500">
          {validationErrors.description}</p>} */}
      </div>
    </form>

    <ImageUploader
      images={images}
      setImages={setImages}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      BASE_URL={BASE_URL}
    />

  </div>
);

export default PostForm;
