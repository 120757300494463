import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CreateAccount from './api/CreateAccount';
import { FaSpinner } from 'react-icons/fa';
import AuthenticatedService from '../../services/AuthenticatedService';
import RightBanner from '../../components/RightBanner';

export default function Setup() {

  const [company, setCompany] = useState('');
  const [fullName, setFullName] = useState('');

  const [companyError, setCompanyError] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [responseCreate, setResponseCreate] = useState('');
  const [responseError, setResponseError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const email = location.state?.email;

  const navigate = useNavigate();

  const inputRef = useRef(null);

  useEffect(function() {
    if (!email) {
      navigate('/signup');
    }
  }, [email, navigate]);

  function validateCompany(value) {
    if (value.trim() === '') {
      return 'Please enter your company name.';
    }
    return '';
  }

  function validateFullName(value) {
    if (value.trim() === '') {
      return 'Please enter your full name.';
    }
    return '';
  }

  function handleCompanyChange(value) {
    const error = validateCompany(value);
    setCompany(value);
    setCompanyError(error);
  }

  function handleFullNameChange(value) {
    const error = validateFullName(value);
    setFullName(value);
    setFullNameError(error);
  }

  const createAccount = async(e) => {
    e.preventDefault();
    // console.log(window.location.host === 'localhost:3000')
    // return false;

    const fullNameError = validateFullName(fullName);
    const companyError = validateCompany(company);

    setCompanyError(companyError);
    setFullNameError(fullNameError);

    if (!fullNameError && !companyError) {
      setIsLoading(true);
      try {

        const res = await CreateAccount(email, company, fullName);
        
        // console.log(res)
  
        if (res.status === 200) {
          setResponseError(false)
          setResponseCreate("Congrats! You have been successfully created your account on Feedvoty.")
          
          let data = res.data;

          AuthenticatedService.registerSuccessfulLogin(data.token, data.refresh_token, data.company_url);

          setTimeout(function() {
            setIsLoading(false);
            // navigate('/set-password');
            let targetUrl = `https://${data.company_url}/set-password`;

            if (window.location.host === 'localhost:3000') {
              targetUrl = 'http://localhost:3000/set-password';
            }

            // Redirect the user to the constructed URL.
            window.location.href = targetUrl;

          }, 2000);
  
        } else {
          setResponseError(true)
          setResponseCreate(res.data || 'An unexpected error occurred');
          // Assuming the response is not OK, handle the error message here
          // const errorResponse = await res.json(); // Make sure to parse the JSON response
          // const errorMessage = errorResponse.detail.map(err => `${err.msg} at ${err.loc.join(' ')}`).join(', ');
          // setResponseCreate(errorMessage);
          setIsLoading(false);
        }
      } catch (error) {
        // setResponseCreate(error);
        setResponseError(false)
        setResponseCreate(error.message || 'An unexpected error occurred');
        setIsLoading(false);
      }
      
    }
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Setup account | Feedvoty</title>
          <meta
            name="description"
            content="Setup account in feedvoty" />
        </Helmet>
        <div className="flex">

          <div className="min-h-screen w-full md:w-5/12 lg:w-5/12 flex flex-col bg-gray-50 px-4 sm:px-6 lg:px-12">
            <div className="w-full space-y-8">
              <div>
                <div className='pt-12 sm:pt-12 md:pt-12 lg:pt-12 text-start'>
                  <img src="./logo.png" alt="logo" className='w-2/12' />
                </div>
                <p className="text-xl lg:text-3xl text-gray-900 pt-20">
                  Setup your 
                  <span className='text-orange-500 font-bold'> company </span>
                </p>
              </div>

              {responseCreate && (
                <div className={`px-7 py-3  border ${responseError ? 'border-red-700 bg-red-100' : 'border-green-700 bg-green-100'} rounded-md`}>
                  <p className={`${responseError ? 'text-red-700' : 'text-green-700'} text-lg mt-1`}>
                    {responseCreate}
                  </p>
                </div>
              )}

              <form className="mt-8 space-y-2" action="#" method="POST">
                
                <div className='mb-3'>
                  <label htmlFor="">
                    What is your company name?
                  </label>
                  <input
                    type="text"
                    autoComplete="company_name"
                    required
                    className={`appearance-none rounded relative block w-full mt-1 px-3 py-3 border ${
                      companyError ? 'border-red-500' : 'border-gray-300'
                    } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm`}
                    placeholder="Your company name.."
                    value={company}
                    onChange={(e) => handleCompanyChange(e.target.value)}
                  />
                  {companyError && (
                    <p className="text-red-500 text-sm mt-1">{companyError}</p>
                  )}
                </div>

                <div className='mb-3'>
                  <label htmlFor="">
                    Your full name
                  </label>
                  <input
                    type="text"
                    autoComplete="full_name"
                    required
                    className={`appearance-none rounded relative block w-full mt-1 px-3 py-3 border ${
                      fullNameError ? 'border-red-500' : 'border-gray-300'
                    } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm`}
                    placeholder="Firstname Lastname"
                    ref={inputRef}
                    value={fullName}
                    onChange={(e) => handleFullNameChange(e.target.value)}
                  />
                  {fullNameError && (
                    <p className="text-red-500 text-sm mt-1">{fullNameError}</p>
                  )}
                </div>
                  
                <div className='text-right'>
                  <button
                    type="submit"
                    className="w-1/2 py-3 px-2 border border-transparent text-sm font-medium rounded-md text-white bg-current bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                    onClick={createAccount}
                  > 
                  {isLoading ? <FaSpinner className="animate-spin" /> : 'CREATE ACCOUNT'}
                  </button>
                </div>

              </form>
            </div>
            
            <div className='items-center justify-center pt-5 text-sm text-gray-500 mt-auto mb-10'>
                By signing up, you are agreed with our
                <a
                  href='https://feedvoty.com/terms'
                  target='_blank'
                  rel="noreferrer"
                  className='underline ml-1'>terms of service</a> and 
                <a
                  href='https://feedvoty.com/privacy'
                  target='_blank'
                  rel="noreferrer"
                  className='underline ml-1 mr-1'>privacy policy</a>
            </div>
          </div>
          <RightBanner />
        </div>

      </HelmetProvider>
    </>
  );
}