import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login/Login';
import SignUp from './pages/Signup/SignUp';
import Setup from './pages/Setup/Setup';
import Verify from './pages/Verify/Verify';
import SetPassword from './pages/SetPassword/SetPassword';
import AuthenticatedRoute from './services/AuthenticatedRoute';
import CreateFirstBoard from './pages/Onboard/CreateBoard';
import CreateBoard from './pages/Admin/CreateBoard/CreateBoard';
import BoardTheme from './pages/Onboard/BoardTheme';
import Success from './pages/Onboard/Success';
import Feedback from './pages/Feedback/Feedback';
import FeedbackAdmin from './pages/Admin/Feedback/FeedbackAdmin';
import Dashboard from './pages/Admin/Dashboard/Dashboard';
import Users from './pages/Admin/Users/Users';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import SendVerification from './pages/SendVerification/SendVerification';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import RoadmapAdmin from './pages/Admin/RoadmapAdmin/RoadmapAdmin';
import "tailwindcss/tailwind.css"

function App() {
  
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/setup" element={<Setup />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/:boardslug" element={<Feedback />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/send-verification" element={<SendVerification />} />
        <Route path="/reset-password/:verification_string" element={<ResetPassword />} />
        <Route path='/set-password' element={<AuthenticatedRoute/>}>
          <Route path="/set-password" element={<SetPassword />} />
        </Route>
        <Route path='/onboard/create-board' element={<AuthenticatedRoute/>}>
          <Route path="/onboard/create-board" element={<CreateFirstBoard />} />
        </Route>
        <Route path='/onboard/board-theme' element={<AuthenticatedRoute/>}>
          <Route path="/onboard/board-theme" element={<BoardTheme />} />
        </Route>
        <Route path='/onboard/success' element={<AuthenticatedRoute/>}>
          <Route path="/onboard/success" element={<Success />} />
        </Route>

        <Route path='/admin/feedback' element={<AuthenticatedRoute/>}>
          <Route path="/admin/feedback" element={<FeedbackAdmin />} />
          <Route path="/admin/feedback/:board_slug/p/:post_slug" element={<FeedbackAdmin />} />
        </Route>

        <Route path='/admin/roadmap' element={<AuthenticatedRoute/>}>
          <Route path="/admin/roadmap" element={<RoadmapAdmin />} />
        </Route>

        <Route path='/admin/users' element={<AuthenticatedRoute/>}>
          <Route path="/admin/users" element={<Users />} />
        </Route>

        <Route path='/admin/create-board' element={<AuthenticatedRoute/>}>
          <Route path="/admin/create-board" element={<CreateBoard />} />
        </Route>

        <Route path='/admin' exact element={<AuthenticatedRoute/>}>
          <Route path="/admin" exact element={<Dashboard />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;