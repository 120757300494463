import { configureStore } from '@reduxjs/toolkit'
import filterSortSlice from './filters/filterSortSlice'
import filterActivitySlice from './filters/filterActivitySlice'
import filterDateSlice from './filters/filterDateSlice'
import responsiveSlice from './componentState/responsiveSlice'
import userPostSlice from './componentState/userPostSlice'

export const userStore = configureStore({
  reducer: {
    filterSort: filterSortSlice,
    filterActivity: filterActivitySlice,
    filterDate: filterDateSlice,
    sidebar: responsiveSlice,
    userPost: userPostSlice
  },
})