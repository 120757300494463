import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SendEmail from './api/SendEmail';
import { FaSpinner } from 'react-icons/fa';
import RightBanner from '../../components/RightBanner';

function SignUp() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const navigate = useNavigate();

  function validateEmail(value) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value.trim() === '') {
      return 'Please enter your email address.';
    } else if (!emailRegex.test(value)) {
      return 'Please enter a valid email address.';
    }
    return '';
  }

  function handleEmailChange(value) {
    const error = validateEmail(value);
    setEmail(value);
    setEmailError(error);
  }

  const createAccount = async(e) => {
    e.preventDefault();

    const emailError = validateEmail(email);
    setEmailError(emailError);

    if (!emailError) {
      // console.log('success')
      // console.log(email)
      setIsLoading(true);
      try {

        const res = await SendEmail(email);
        setIsLoading(false);
        
        // console.log(res)
  
        if (res.status === 200) {
          navigate('/verify', { state: { email: email } });
        }
      } catch (error) {
        setIsLoading(false);
        if (error.message.includes("Failed to fetch")) {
          setEmailError("Failed to send email! Can you please try again after some time?");
        } else {
          setEmailError(error.message);
        }
      }
    }
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Sign up | Feedvoty</title>
          <meta
            name="description"
            content="Sign up for a feedvoty account" />
        </Helmet>
        <div className="flex">

          <div className="min-h-screen w-full md:w-5/12 lg:w-5/12 flex flex-col 
            bg-gray-50 px-4 sm:px-6 lg:px-12">
            <div className="w-full space-y-8">
              <div>
                <div className='pt-12 sm:pt-12 md:pt-12 lg:pt-12 text-start'>
                  <img src="./logo.png" alt="logo" className='w-2/12' />
                </div>
                <p className="text-xl lg:text-3xl text-gray-900 pt-20">
                  Hey there! 👋 Let’s set-up your own <br />
                  <span className='text-orange-500 font-bold'> FeedVoty </span> dashboard
                </p>
              </div>
              <form className="mt-8 space-y-2" action="#" method="POST">
                <label htmlFor="email">
                  What is your email id?
                </label>
                <input
                  type="email"
                  autoComplete="email"
                  id='email'
                  required
                  className={`appearance-none rounded relative block w-full 
                    px-3 py-3 border 
                    ${
                    emailError ? 'border-red-500' : 'border-gray-300'
                    } placeholder-gray-500 text-gray-900 focus:outline-none 
                    focus:ring-orange-500 focus:border-orange-500 focus:z-10
                    sm:text-sm`}
                  placeholder="Your email id"
                  value={email}
                  onChange={(e) => handleEmailChange(e.target.value)}
                />
                {emailError && (
                  <p className="text-red-500 text-sm mt-1">{emailError}</p>
                )}
                  
                <div className='text-right'>
                  <button
                    type="submit"
                    className={`flex items-center justify-center py-3 
                      px-2 border border-transparent text-sm font-medium
                      rounded-md text-white bg-current w-full md:w-1/2
                      ${isLoading ? 'bg-gray-300' : 'bg-orange-500'}  
                      ${isLoading ? '' : 'hover:bg-orange-600'} 
                      focus:outline-none focus:ring-2 focus:ring-offset-2
                      ${isLoading ? '' : 'focus:ring-orange-500'}`}
                    onClick={createAccount}
                  >
                    {isLoading ? <FaSpinner className="animate-spin mr-2" /> : null}
                    {isLoading ? 'Sending...' : 'PROCEED NEXT'}
                  </button>
                </div>

                <div className='flex items-center justify-center pt-5'>
                  <Link to="/login" className="hover:underline">Already have an account? Log in</Link>
                </div>

              </form>
            </div>
            
            <div className='items-center justify-center pt-5 text-sm text-gray-500 mt-auto mb-10'>
                By signing up, you are agreed with our
                <a
                  href='https://feedvoty.com/terms'
                  target='_blank'
                  rel="noreferrer"
                  className='underline ml-1'>terms of service</a> and 
                <a
                  href='https://feedvoty.com/privacy'
                  target='_blank'
                  rel="noreferrer"
                  className='underline ml-1 mr-1'>privacy policy</a>
            </div>
          </div>

          <RightBanner />
        </div>
      </HelmetProvider>
    </>
  )
}

export default SignUp;