import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {
    posts: true,
    votes: false,
    comments: false
  },
}

export const filterActivitySlice = createSlice({
  name: 'filterActivity',
  initialState,
  reducers: {
    updateFilterActivity: (state, action) => {
      const { id, checked } = action.payload;
      state.value[id] = checked;
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateFilterActivity } = filterActivitySlice.actions

export default filterActivitySlice.reducer