import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startDate: '',
  endDate: ''
}

export const filterDateSlice = createSlice({
  name: 'filterDate',
  initialState,
  reducers: {
    updateFilterDate: (state, action) => {
      const { startDate, endDate } = action.payload;
      if (startDate !== undefined && endDate !== undefined) {
        state.startDate = startDate;
        state.endDate = endDate;
      }
    }
  }
})

export const { updateFilterDate } = filterDateSlice.actions;

export default filterDateSlice.reducer