import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSidebarOpen: false,
}

export const responsiveSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    checkIfMobile: (state, action) => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        state.isSidebarOpen = false
      } else {
        state.isSidebarOpen = true
      }
    },
    updateSideBarState: (state, action) => {
      state.isSidebarOpen = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { checkIfMobile, updateSideBarState } = responsiveSlice.actions

export default responsiveSlice.reducer