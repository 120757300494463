const SendEmail = async(email) => {
	const BASE_URL = process.env.REACT_APP_BASE_URL;
  const data = {
    "email": email
  }
  try {
    const response = await fetch(`${BASE_URL}/signup/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' // Ensure you set the content type header
      },
      body: JSON.stringify(data)
    });

    const responseData = await response.json();
    // console.log(responseData)
    if (!response.ok) {
      if (responseData.detail.includes("is already exist")) {
        throw new Error(responseData.detail);
      } else {
        throw new Error(`Failed to send email!`);
      }
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    // console.error(error);
		throw new Error(error.message);
  }
}

export default SendEmail