import AuthenticatedService from '../../../services/AuthenticatedService';

export const UpdatePassword = async(password, recovery_string) => {
	const BASE_URL = process.env.REACT_APP_BASE_URL;
  const data = {
    "new_password": password,
    "recovery_string": recovery_string
  }
  
  try {
    const response = await fetch(`${BASE_URL}/users/reset_password/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
		  // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
		throw new Error(error.message);
  }
}

export const ValidationVerificationString = async(string) => {
	const BASE_URL = process.env.REACT_APP_BASE_URL;
 
  const token = AuthenticatedService.getToken(); 
  
  try {
    const response = await fetch(`${BASE_URL}/users/verify_recover_string/${string}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
		  // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
		throw new Error(error.message);
  }
}