import AuthenticatedService from '../../../../services/AuthenticatedService';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const FetchUsers = async (
  dateFrom,
  dateTo,
  selectedSort, 
  selectedActivity, 
  searchKey
) => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken(); 
  // console.log(selectedSort)
  // console.log(selectedActivity)
  // console.log(searchKey)

  const queryParams = new URLSearchParams({
    date_from: dateFrom,
    date_to: dateTo,
    sorts: selectedSort,
    search: searchKey,
    'activity.posts': selectedActivity.posts,
    'activity.votes': selectedActivity.votes,
    'activity.comments': selectedActivity.comments
  });

  try {
    const response = await fetch(`${BASE_URL}/users/filters/?${queryParams.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      }
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
}

export const FetchPostByUser = async (userId) => {
  await AuthenticatedService.isUserLoggedIn();
  const token = AuthenticatedService.getToken(); 
  
  try {
    const response = await fetch(`${BASE_URL}/posts/post_by_user/${userId}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      }
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
      // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
}