import React from 'react';

export default function RightBanner() {
  return (
    <div className="min-h-screen md:w-7/12 hidden sm:block items-center justify-center bg-gray-10 px-4 sm:px-6 lg:px-8 bg-orange-500">
      <div className="flex flex-col items-center justify-center">
          <div className='pt-24'>
            <img src="./feedvoty_logo_banner.png" alt="logo" className='mx-auto w-10/12' />
          </div>
          <div className='text-center'>
            <p className='text-white text-5xl'>Feedvoty</p>
          </div>
      </div>
    </div>
  );
}