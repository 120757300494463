import { useCallback, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { FaSpinner } from 'react-icons/fa';
import { UpdatePassword, ValidationVerificationString } from './utils/api';
import LoadingIcon from "../../components/LoadingIcon";

export default function ResetPassword() {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { verification_string } = useParams();
  const [pageLoading, setPageLoading] = useState(true);
  const [verificationError, setVerificationError] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);

  const validateVerificationString = useCallback(async () => {
    // console.log(verification_string);
    try {
      const res = await ValidationVerificationString(verification_string);
      if (res.status === 200) {
        setPageLoading(false);
      } else {
        setVerificationError(res.data || 'An unexpected error occurred');
        setIsLoading(false);
      }
      setPageLoading(false);
    } catch (error) {
      setVerificationError(error.message || 'An unexpected error occurred');
      setIsLoading(false);
      setPageLoading(false);
    }
  },[verification_string]);

  useEffect(() => {
    validateVerificationString();
  }, [validateVerificationString]);

  function validatePassword(value) {
    if (value.trim() === '') {
      return 'Please enter password';
    }

    if (value.length < 8) {
      return 'Minimum of 8 characters needed to create password';
    }
    return '';
  }

  const handlePassword = (value) => {
    const error = validatePassword(value);
    setPassword(value);
    setPasswordError(error);
  }

  const handleRepeatPassword = (value) => {
    let error = validatePassword(value);
    setRepeatPassword(value);
    if (value !== password) {
      error = "Repeat password didn't matched.";
    }
    setPasswordError(error);
  }

  const change = async (e) => {
    e.preventDefault();

    let error = validatePassword(password);

    setPasswordError(error);

    if (password !== repeatPassword) {
      error = "Repeat password didn't matched.";
    }
    setPasswordError(error);

    if (!error) {
      setIsLoading(true);

      try {
        const res = await UpdatePassword(password, verification_string);
        if (res.status === 200) {
            // navigate('/onboard/create-board');
          setResetSuccess(true);
        } else {
          setPasswordError(res.data || 'An unexpected error occurred');
          setIsLoading(false);
        }
      } catch (error) {
        setPasswordError(error.message || 'An unexpected error occurred');
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Set your password | Feedvoty</title>
        </Helmet>
        <div className="w-full min-h-screen flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-12">
        {!resetSuccess ? (
          verificationError ? (
            <div>{verificationError}</div>
          ) : (
            pageLoading ? (
              <div className="flex flex-col items-center">
                <LoadingIcon />
                <div className="mt-3">Verification is in process..</div>
              </div>
            ) : (
              <div className="w-full space-y-8">
                <div className="text-center">
                  <img src="../logo.png" alt="logo" className='mx-auto md:w-1/12 w-1/4' />
                  <p className="mt-6 text-xl lg:text-3xl text-gray-900">
                    Now it's time to reset your 🔑 <span className='text-orange-500 font-bold'>password</span>
                  </p>
                </div>
                
                <div className="flex justify-center">
                  <div className="w-full md:w-3/4 lg:w-1/4">
                    {passwordError.length > 0 && (
                      <div className="px-7 py-3 border border-red-700 bg-red-100 rounded-md">
                        <p className="text-red-700 text-lg mt-1">
                          {passwordError}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                
                <form className="mt-8 space-y-6" action="#" method="POST">
                  <div className="flex justify-center">
                    <div className="w-full md:w-3/4 lg:w-1/4">
                      <label htmlFor="password" className="sr-only">Password</label>
                      <input
                        type="password"
                        required
                        className={`appearance-none rounded relative block w-full px-3 py-3 border ${
                          passwordError ? 'border-red-500' : 'border-gray-300'
                        } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm`}
                        placeholder="Enter your password"
                        onChange={(e) => handlePassword(e.target.value)}
                      />
                    </div>
                  </div>
                  
                  <div className="flex justify-center">
                    <div className="w-full md:w-3/4 lg:w-1/4">
                      <label htmlFor="repeat-password" className="sr-only">Repeat Password</label>
                      <input
                        type="password"
                        required
                        className={`appearance-none rounded relative block w-full px-3 py-3 border ${
                          passwordError ? 'border-red-500' : 'border-gray-300'
                        } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm`}
                        placeholder="Re-enter password"
                        onChange={(e) => handleRepeatPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  
                  <div className="flex justify-center">
                    <div className="w-full md:w-3/4 lg:w-1/4">
                      <button
                        type="submit"
                        className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                        onClick={change}
                      >
                        {isLoading ? <FaSpinner className="animate-spin" /> : 'UPDATE PASSWORD'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )
          )
          ) : (
            <div className="text-center">
              <img src="../logo.png" alt="logo" className='mx-auto md:w-1/12 w-1/4' />
              <p className="mt-6 text-xl lg:text-3xl text-gray-900">
                🔑 <span className='text-orange-500 font-bold'>Password</span> reset successful.
              </p>
              <div className="mt-7">
                <Link to="/login">Go back to Login</Link>
              </div>
            </div>
        )}
        </div>
      </HelmetProvider>
    </>
  );
  
    
}