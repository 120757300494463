import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FaSpinner } from 'react-icons/fa';
import RightBanner from '../../components/RightBanner';
import DoLogin from './api/DoLogin';
import AuthenticatedService from '../../services/AuthenticatedService';

function Login() {

  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    AuthenticatedService.isUserLoggedIn()
        .then(isLoggedIn => {
          console.log(isLoggedIn);
          // window.location.href = 
        })
        .catch(() => {
          console.log(false);
        });
  }, []);

  const validateUsername = (value) => { 
    if (value.trim() === '') {
      return 'Please enter email id';
    }
    return '';
  }

  const validatePassword = (value) => { 
    if (value.trim() === '') {
      return 'Please enter password';
    }
    return '';
  }

  const handleUsername = (value) => {
    const error = validateUsername(value);
    setUsername(value);
    setUsernameError(error);
  }

  const handlePassword = (value) => {
    const error = validatePassword(value);
    setPassword(value);
    setPasswordError(error);
  }

  const doLogin = async (e) => {
    e.preventDefault();
    const usernameError = validateUsername(username);
    const passwordError = validatePassword(password);

    setUsernameError(usernameError);
    setPasswordError(passwordError);

    if (!usernameError && !passwordError) {
      setIsLoading(true);
      try {
        const res = await DoLogin(username, password);
        if (res.status === 200) {
          let data = res.data;
          AuthenticatedService.registerSuccessfulLogin(data.token, data.refresh_token, data.company_url);
          
          let targetUrl = `https://${data.company_url}/admin`;

          if (window.location.host === 'localhost:3000') {
            targetUrl = `http://localhost:3000/admin`;
          }

          // Redirect the user to the constructed URL.
          window.location.href = targetUrl;
        } else {
          setPasswordError(res.data || 'An unexpected error occurred');
          setIsLoading(false);
        }
      } catch (error) {
        setPasswordError(error.message || 'An unexpected error occurred');
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Log in | Feedvoty</title>
          <meta name="description" content="Log in feedvoty account" />
        </Helmet>
        <div className="flex">

          <div className="min-h-screen w-full md:w-5/12 lg:w-5/12 flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-16">
            <div className="w-full space-y-8">
              <div>
                <div>
                  <img src="./logo.png" alt="logo" className='mx-auto my-auto w-3/12' />
                </div>
                <p className="text-center text-xl text-gray-900 pt-5">
                  Login to your account
                </p>
              </div>
              <form className="mt-8 space-y-6" action="#" method="POST">
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="Email" className="sr-only">
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="text"
                      value={username}
                      autoComplete="email"
                      required
                      className={`${
                        passwordError ? 'border-red-500' : 'border-gray-300'
                      } appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm`}
                      placeholder="Email"
                      onChange={(e) => handleUsername(e.target.value)}
                    />
                    {usernameError.length > 0 && (
                      <p className="text-red-500 text-sm mt-1 mb-1">
                        {usernameError}
                      </p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      value={password}
                      autoComplete="current-password"
                      required
                      className={`border ${
                        passwordError ? 'border-red-500' : 'border-gray-300'
                      } appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm`}
                      placeholder="Password"
                      onChange={(e) => handlePassword(e.target.value)}
                    />
                    {passwordError.length > 0 && (
                      <p className="text-red-500 text-sm mt-1">
                        {passwordError}
                      </p>
                    )}
                  </div>
                </div>

                <div className='flex'>
                  <div className='w-full'>
                    <Link to="/forgot-password" className="hover:underline">Forgot password?</Link>
                  </div>
                  <div className='w-full'>
                    <button
                      type="submit"
                      className="w-full justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-current bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                      onClick={doLogin}
                    > 
                    {isLoading ? <FaSpinner className="animate-spin" /> : 'LOG IN'}
                    </button>
                  </div>
                </div>

                <div className='flex items-center justify-center pt-5'>
                  <Link to="/signup" className="hover:underline">Don't have an account? Register here</Link>
                </div>
              </form>
            </div>
          </div>
          <RightBanner />
        </div>
      </HelmetProvider>
    </>
  )
}

export default Login;