import { useCallback, useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoIosPersonAdd } from "react-icons/io";
import { useSelector, useDispatch } from 'react-redux'
import debounce from 'lodash/debounce';

import { updateUserPostState, updateSelectedUser }
  from '../../../../redux/users/componentState/userPostSlice';
import { FetchUsers } from '../utils/api';

const UserList = () => {
  const selectedSort = useSelector((state) => state.filterSort.value)
  const selectedActivity = useSelector((state) => state.filterActivity.value)
  const dateFrom = useSelector((state) => state.filterDate.startDate)
  const dateTo = useSelector((state) => state.filterDate.endDate)
  const isUserPostOpen = useSelector((state) => state.userPost.isPostOpen)

  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);

  const [trackedUser, setTrackedUser] = useState(1);
  const [searchKey, setSearchKey] = useState('');

  const GetUsers = useCallback(async () => {
    // fetch all users
    const users = await FetchUsers(
      dateFrom,
      dateTo,
      selectedSort,
      selectedActivity,
      searchKey
    );
    setUsers(users.data.users);
    setTrackedUser(users.data.total_count);
  }, [dateFrom, dateTo, selectedSort, selectedActivity, searchKey]);
  
  useEffect(() => {
    GetUsers();
  }, [GetUsers]);

  const debouncedSearchKey = useRef(
    debounce((value) => setSearchKey(value), 300)
  ).current;

  const handleSearchFilter = (searchValue) => {
    debouncedSearchKey(searchValue);
  }

  const handleUserSelect = (user) => {
    dispatch(updateUserPostState(!isUserPostOpen));
    dispatch(updateSelectedUser(user));
  }

  return (
    <div className={`${isUserPostOpen ? 'hidden md:flex' : 'flex'} flex-col
      w-full md:w-3/12 bg-gray-100 dark:bg-gray-800 dark:text-white`}>

      <div className="p-4">
        {trackedUser} Tracked user ({trackedUser} total)
      </div>

      <div className='flex'>
        <div className='w-10/12 relative z-0'>
          <FiSearch
            size={20}
            className='absolute left-3 top-1/2 transform 
            -translate-y-1/2 text-gray-400 z-0'
          />
          <input
            type="text"
            placeholder="Search here.."
            className='pl-10 pr-3 py-3 border-slate-300 bg-gray-50
            focus:border-orange-500 focus:outline-none 
            focus:bg-white w-full
            dark:bg-gray-800
            dark:focus:bg-gray-700
            dark:hover:bg-gray-700
            border-solid border z-0 hover:bg-white'
            onChange={(e) => handleSearchFilter(e.target.value)}
          />
        
        </div>
        <div className='w-2/12'>
          <div className='justify-center items-center text-center pt-2.5
          border border-slate-300 w-full h-full 
          bg-gray-100 cursor-pointer hover:border-orange-500
          dark:bg-gray-800'
            // onClick={() => showCreatePostModal()}
          >
            <IoIosPersonAdd
              size={25}
              className='cursor-pointer text-slate-700 w-full
              hover:text-orange-500 dark:text-gray-100'
            />
          </div>
        </div>
      </div>

      <div className="px-0.5">
        {users && users.map((user, index) => (
          <div
            key={index}
            className='p-3 mb-0.5 bg-white hover:cursor-pointer 
              h-16 dark:bg-gray-700'
            onClick={() => handleUserSelect(user)}
          >
            <div className='text-gray-700 dark:text-gray-100'>
              {user.full_name}
            </div>
            <div className='text-sm text-gray-500 dark:text-gray-200'>
              {user.email}
            </div>
          </div>
          ))
        }
      </div>
    </div>
  );
}

export default UserList 