import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {
  IoMdArrowDropup,
  IoIosArrowBack,
} from "react-icons/io";
import {
  FaCommentAlt
} from "react-icons/fa";

import { FetchPostByUser } from '../utils/api';
import { updateUserPostState }
  from '../../../../redux/users/componentState/userPostSlice';

export default function UserPosts() {

  const isSidebarOpen = useSelector((state) => state.sidebar.isSidebarOpen)
  const isUserPostOpen = useSelector((state) => state.userPost.isPostOpen)
  const selectedUser = useSelector((state) => state.userPost.selectedUser)

  const [postsByUser, setPostsByUser] = useState([]);
  const [msg, setMsg] = useState("Please select a user from left");

  const dispatch = useDispatch();

  const fetchPostsByUser = useCallback(async () => {
    const posts = await FetchPostByUser(selectedUser.id);

    setMsg("");
    if(posts.status === 200) {
      setPostsByUser(posts.data);
    } else {
      setPostsByUser([]);
    }

    if (posts.status === 404) {
      setMsg(posts.data);
    }
  }, [selectedUser]);
  
  useEffect(() => {
    if (selectedUser.id) {
      fetchPostsByUser();
    }
  }, [fetchPostsByUser, selectedUser]);

  return (
    <div className={`
      ${!isUserPostOpen ? 'hidden md:flex' : 'flex'} flex-col w-full 
      ${isSidebarOpen ? 'md:w-5/12' : 'md:w-6/12'}  overflow-y-auto border
      border-r-1 dark:bg-gray-800 dark:text-white`}>

      <div className='md:hidden p-3 flex dark:text-gray-200 cursor-pointer'
        onClick={() => dispatch(updateUserPostState(!isUserPostOpen))}
      >
        <IoIosArrowBack size={25} /> Back to list
      </div>
      
      {msg && (
        <div className="flex justify-center items-center h-screen">
          {msg}
        </div>
      )}

      <div>
        {postsByUser &&
          postsByUser.map((post, index) => (
            // {console.log(post.board_title)}
            <Link
            to={`/admin/feedback/${post.board_slug}/p/${post.post_slug}?status=${post.status_slug}`}
            >
              <div
                key={index}
                className='p-4 mb-0.5 bg-gray-50 hover:cursor-pointer 
                dark:bg-gray-700'
              >
                <div className='text-gray-600 dark:text-gray-100 font-bold'>
                  {post.post_title}
                </div>
                
                <div className='text-sm text-gray-500 dark:text-gray-200'>
                  {post.post_content}
                  </div>

                <div className="flex justify-between">
                  <div className='flex mt-1'>
                    <IoMdArrowDropup
                      size={30}
                      className={`
                      ${post.user_voted ? 'text-orange-500' : 'text-gray-500 dark:text-gray-300'}`
                      }
                    />
                    <span className='mt-1 text-sm text-gray-500 dark:text-gray-300'>
                      {post.no_of_vote}
                    </span>

                    <FaCommentAlt
                      size={12}
                      className='mt-2 ml-5 mr-2 text-gray-500 dark:text-gray-300'
                    />
                    <span className='mt-1 text-sm text-gray-500 dark:text-gray-300'>
                      {post.no_of_comment}
                    </span>
                  </div>
                  
                  <div className="flex space-x-1">
                    <div className='text-xs mt-3 px-2 py-0.5 bg-orange-600 text-white inline-block rounded-sm'>
                      {post.board_title}
                    </div>
                    <div className='text-xs mt-3 px-2 py-0.5 bg-orange-600 text-white inline-block rounded-sm'>
                      {post.status_name}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))
        }
      </div>
    </div>
  );
}