import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VerifyCode from './api/VerifyCode';
import { FaSpinner } from 'react-icons/fa';
import RightBanner from '../../components/RightBanner';

export default function Verify() {
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState('');
  const [verificationCodeSuccess, setVerificationCodeSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const email = location.state?.email;

  const navigate = useNavigate();

  // console.log(email)
  useEffect(function() {
    if (!email) {
      navigate('/signup');
    }
  }, [email, navigate]);

  function validateVerificationCode(value) {
    if (value.trim() === '') {
      return 'Please enter the verification code.';
    }
    return '';
  }

  const handleVerificationCode = (value) => {
    const error = validateVerificationCode(value);
    setVerificationCode(value);
    setVerificationCodeError(error);
  }

  const createAccount = async (e) => {
    e.preventDefault();

    const error = validateVerificationCode(verificationCode);
    setVerificationCodeError(error);

    // console.log(email)
    // console.log(verificationCode)

    if (!error) {
      setIsLoading(true);

      try {

        const res = await VerifyCode(email, verificationCode);
        
        // console.log(res)
  
        if (res.status === 200) {
          setVerificationCodeSuccess("Successfully verified")
  
          setTimeout(function() {
            setIsLoading(false);
            navigate('/setup', { state: { email: email } });
          }, 2000);
  
        } else {
          setVerificationCodeError(res.data);
          setIsLoading(false);
        }
      } catch (error) {
        setVerificationCodeError(error);
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title> Verify your email id | feedvoty signup </title>
        </Helmet>
        <div className="flex">
          <div className="min-h-screen w-full md:w-5/12 lg:w-5/12 flex flex-col bg-gray-50 px-4 sm:px-6 lg:px-12">
            <div className="w-full space-y-8">
              <div>
                <div className='pt-12 sm:pt-12 md:pt-12 lg:pt-12 text-start'>
                  <img src="./logo.png" alt="logo" className='w-2/12' />
                </div>
                <p className="text-xl lg:text-3xl text-gray-900 pt-20">
                  Now it's time to verify your email id! 👋 Just one step more to create your own <br />
                  <span className='text-orange-500 font-bold'> FeedVoty </span> dashboard
                </p>
              </div>

              {verificationCodeSuccess && (
                <div className="px-7 py-3 bg-green-100 border border-green-700 rounded-md">
                  <p className="text-green-700 text-lg mt-1">
                    {verificationCodeSuccess}
                  </p>
                </div>
              )}
              
              <form className="mt-8 space-y-2" action="#" method="POST">
                <label htmlFor="verification_code">
                  Please enter the verification code
                </label>
                <input
                  type="password"
                  id='verification_code'
                  required
                  className={`appearance-none rounded relative block w-full px-3 py-3 border ${
                    verificationCodeError ? 'border-red-500' : 'border-gray-300'
                  } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm`}
                  placeholder="* * * *"
                  value={verificationCode}
                  onChange={(e) => handleVerificationCode(e.target.value)}
                />
                {verificationCodeError && (
                  <p className="text-red-500 text-sm mt-2 pb-5">
                    {verificationCodeError}
                    <Link to="/signup" className="text-black"> Click here to go to signup page again.</Link>
                  </p>
                )}

                <div className='text-right'>
                  <button
                    type="submit"
                    className="inline-flex justify-center items-center w-1/2 py-3 px-2 border border-transparent text-sm font-medium rounded-md text-white bg-current bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                    onClick={createAccount}
                  > 
                    {isLoading ? <FaSpinner className="animate-spin" /> : 'VERIFY'}
                  </button>
                </div>

                <div className='flex items-center justify-center pt-5'>
                  <Link to="/login" className="hover:underline">Already have an account? Log in</Link>
                </div>
              </form>
            </div>
          
            <div className='items-center justify-center pt-5 text-sm text-gray-500 mt-auto mb-10'>
                By signing up, you are agreed with our
                <a
                  href='https://feedvoty.com/terms'
                  target='_blank'
                  rel="noreferrer"
                  className='underline ml-1'>terms of service</a> and 
                <a
                  href='https://feedvoty.com/privacy'
                  target='_blank'
                  rel="noreferrer"
                  className='underline ml-1 mr-1'>privacy policy</a>
            </div>
          </div>

          <RightBanner />
        </div>
        
      </HelmetProvider>
    </>
  );  
}