import React from 'react';

export default function BoardForm({
  boardName, boardError, boardURL, handleBoard, handleSubmit, submitBtnLabel
}) {
  return (
    <form>
    <div className="mb-4 pt-4">
      <label htmlFor="boardName" className="block text-sm font-medium 
        text-gray-700 dark:text-gray-50">Please enter a name for your board</label>
      <input
        type="text"
        id="boardName"
        value={boardName}
          className={`appearance-none rounded relative block w-full mt-1 px-3 py-3 
          border ${
          boardError ? 'border-red-500' : 'border-gray-300'
          } placeholder-gray-500 text-gray-900 focus:outline-none 
          dark:bg-gray-700 dark:text-gray-50
        focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm`}
        placeholder="E.g., 'Feature request', 'Bugs', 'issues' etc"
        required
        onChange={(e) => handleBoard(e.target.value)}
      />
      {boardError && (
        <p className="text-red-500 text-sm mt-1">{boardError}</p>
      )}
    </div>
    
    <div className="flex justify-center mb-6 text-sm dark:text-gray-50">
    {boardURL.length > 0 &&
      `Board URL: ${boardURL}`
    }
    </div>
    <button
      type="submit"
      className="w-full px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 focus:outline-none focus:bg-orange-600"
      onClick={handleSubmit}
    >
      {submitBtnLabel}
    </button>
  </form>
  )
}