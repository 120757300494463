import React, { useEffect, useState } from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import { IoIosCheckbox } from "react-icons/io";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";

import CreatePostApi from './api/CreatePostApi';
import PostForm from './components/PostForm';
import UserSelect from './components/UserSelect';

const CreatePost = ({isOpen, onClose, boards, users, insertPostData}) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [createNewUser, setCreateNewUser] = useState(false);
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [userId, setUserId] = useState("");

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [values, setValues] = useState({
    title: '',
    description: '',
    board_id: '',
  });

  // Effect to set the default board_id when boards are loaded/updated
  useEffect(() => {
    if (boards && boards.length > 0) {
      setValues((values) => ({ ...values, board_id: boards[0].id }));
    }
  }, [boards]);

  const handleChange = (event) => {
    const fieldName = event.target.name;

    // Clear the validation error for the changed field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));

    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  }

  const updateUserId = (userId) => {
    setNewUserName("");
    setNewUserEmail("");
    setUserId(userId);
  }

  // const handleContentEditableChange = (event) => {
  //   const fieldName = event.target.getAttribute('name');
  //   const newValue = event.target.innerHTML; // Get the current text inside the contentEditable div

  //   // Clear the validation error for the changed field
  //   setValidationErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [fieldName]: '',
  //   }));

  //   setValues({
  //     ...values,
  //     [fieldName]: newValue,
  //   });
  // }

  const submit = async (e) => {
    e.preventDefault();
    
    // Clear any previous validation errors
    setValidationErrors({});
    // Validate each field
    const errors = {};

    if (!values.title) {
      errors.title = 'Title is required';
    }

    if (Object.keys(errors).length > 0) {
      // Update the validation errors state
      setValidationErrors(errors);
      return;
    }
    // console.log(values)
    
    let owner_id = userId;
    if (!userId) {
      owner_id = null;
    }

    const data = {
      title: values.title,
      content: values.description,
      board_id: values.board_id,
      is_private: isPrivate,
      new_user_name: newUserName,
      new_user_email: newUserEmail,
      owner_id: owner_id,
      images: images
    }

    // console.log(data)
    const res = await CreatePostApi(data);
    // console.log(res)
    if (res.status === 201) {
      onClose();
      insertPostData(values.board_id);
    }
  }

  const handleOnClose = async () => {
    onClose();
  }

  const onCreateUser = async () => {
    setCreateNewUser(true);
  }

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      {/* Black shadow */}
      <div
        className={`fixed inset-0 bg-black opacity-50 dark:opacity-80 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={handleOnClose}
      ></div>

      <div className="bg-white dark:bg-gray-800 dark:text-gray-200
        rounded shadow-md 
        relative t-0 w-full 
        md:w-8/12 max-h-full overflow-y-auto">
        <div className="flex justify-between items-center p-3 md:p-5">
          <div className='flex flex-wrap items-center'>

            <div className="flex items-center">
              <label htmlFor="board_id" className='font-bold mr-10 md:mr-3'>
                Board
              </label>
              <select
                name="board_id"
                id="board_id"
                className='rounded-md border-slate-300 px-3 py-2 border-solid 
                  border-2 hover:border-orange-500 focus:border-orange-500
                  dark:bg-gray-800 dark:focus:bg-gray-700 dark:text-gray-200
                  focus:outline-none w-56 md:w-full'
                value={values.board_id}
                onChange={handleChange}
              >
              {boards.map((board, index) => (
                <option key={index} value={board.id}>{board.title}</option>
              ))}
              </select>
            </div>

            <div className="flex items-center md:ml-5 mt-3 md:mt-0">
              <label htmlFor="user-select" className='font-bold mr-3'>On behalf</label>
              <div className='min-w-[220px]'>
                {!createNewUser && (
                  <UserSelect
                    users={users}
                    onUserChange={(userId) => updateUserId(userId)}
                    onCreateUser={onCreateUser}
                  />
                )}

                {createNewUser && (
                  <>
                    <input
                      type="text"
                      className='px-3 py-2 rounded-md border-gray-300 
                        focus:border-orange-500 focus:outline-none
                        border-solid border-2
                        dark:bg-gray-800 dark:focus:bg-gray-700 dark:text-gray-200'
                      placeholder='user name'
                      value={newUserName}
                      onChange={(e) => setNewUserName(e.target.value)}
                    />

                    <input
                      type="email"
                      className='px-3 py-2 rounded-md border-gray-300 
                        focus:border-orange-500 focus:outline-none
                        border-solid border-2 ml-3
                        dark:bg-gray-800 dark:focus:bg-gray-700 dark:text-gray-200'
                      placeholder='email (optional)'
                      value={newUserEmail}
                      onChange={(e) => setNewUserEmail(e.target.value)}
                    />

                    <button
                      type="button"
                      className='text-gray-500 hover:text-gray-700
                      dark:text-gray-200 dark:focus:text-gray-300
                        focus:outline-none focus:ring-2 focus:ring-gray-500 
                        focus:ring-opacity-50 px-4 py-2 text-sm'
                      onClick={() => setCreateNewUser(false)}
                    >
                      Cancel
                    </button>
                  </>
                )}
              </div>
            </div>

          </div>
          <span onClick={handleOnClose} className="cursor-pointer">
            <AiOutlineClose size={23} />
          </span>
        </div>

        <div className='flex border-b border-gray-300'></div>

        <div className="md:flex">
          <PostForm
            handleChange={handleChange}
            handleContentEditableChange={handleChange}
            values={values}
            validationErrors={validationErrors}
            images={images}
            setImages={setImages}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            BASE_URL={BASE_URL}
          />
          <div className="w-full md:w-1/2 p-5">
            <p><span className='font-bold'>OR</span> add your vote to an existing post..</p>
          </div>

        </div>

        <div className='border-t border-gray-300 p-5'>

          <div className='flex justify-between'>
            <button type="button" className="px-4 py-2 text-red-500 rounded"
              onClick={handleOnClose}
              tabIndex={-1}
            >
              Close
            </button>

            <div className='flex'>
              <div className='flex mt-1'>
                {isPrivate ?
                  <IoIosCheckbox
                    size={22}
                    className='mt-1 text-orange-500 cursor-pointer'
                    onClick={() => setIsPrivate(!isPrivate)}
                  /> :
                  <MdOutlineCheckBoxOutlineBlank
                    size={22}
                    className='mt-1 text-gray-400 cursor-pointer'
                    onClick={() => setIsPrivate(!isPrivate)}
                  />
                }
                <span className='mt-0.5 mr-10 ml-0.5 text-gray-600 dark:text-gray-200'>
                  Private
                </span>
              </div>
              <button className='px-4 py-2 rounded focus:bg-orange-300 
                bg-orange-500 text-white'
                onClick={submit}
                >
                Create post
              </button>
            </div>
          </div>
          
        </div>
      </div>

    </div>
  );
};

export default CreatePost;
