import React from 'react';
import {AiOutlineClose, AiOutlineLoading3Quarters} from 'react-icons/ai';
import { BiImageAdd } from "react-icons/bi";
import AuthenticatedService from '../../../../services/AuthenticatedService';

const ImageUploader = ({ images, setImages, isLoading, setIsLoading, BASE_URL }) => {

  const handleImageChange = async (e) => {

    await AuthenticatedService.isUserLoggedIn();
    const token = AuthenticatedService.getToken(); 

    if (e.target.files && e.target.files[0]) {
      setIsLoading(true);
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await fetch(`${BASE_URL}/posts/upload-image`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-subdomain': window.location.hostname
          },
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          setImages([...images, data.fileUrl]);
        } else {
          alert('Image upload failed');
        }
      } catch (error) {
        console.error('Upload error:', error);
        alert('Image upload failed');
      }
      setIsLoading(false);
    }
    e.target.value = null;
  };

  const removeImage = async (index) => {
    await AuthenticatedService.isUserLoggedIn();
    const token = AuthenticatedService.getToken(); 

    const imageToRemove = images[index];
    setIsLoading(true);
    await fetch(`${BASE_URL}/posts/delete-image`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-subdomain': window.location.hostname
      },
      body: JSON.stringify({ image_url: imageToRemove }),
    });
    setImages(images.filter((_, idx) => idx !== index));
    setIsLoading(false);
  };

  return (
    <div className='flex items-center'>
      <div className='p-2'>
        <label htmlFor="image-upload" className="cursor-pointer">
          {isLoading ? (
            <AiOutlineLoading3Quarters className="animate-spin text-3xl text-gray-500 cursor-wait" />
          ) : (
            <BiImageAdd className="text-3xl text-gray-500 cursor-pointer" />
          )}
        </label>
        <input
          id="image-upload"
          type="file"
          name='file'
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
      </div>
      <div className='flex flex-wrap'>
        {images.map((image, index) => (
          <div key={index} className='relative m-2'>
            <img src={image} alt="upload" className='w-20 h-20 object-cover' />
            <AiOutlineClose
              className='absolute top-0 right-0 text-black 
                cursor-pointer bg-white/50 hover:bg-white/75'
              onClick={() => removeImage(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageUploader;
