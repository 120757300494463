import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import AuthenticatedService from '../services/AuthenticatedService';

const AuthenticatedRoute = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        AuthenticatedService.isUserLoggedIn()
            .then(isLoggedIn => {
                setIsAuthenticated(isLoggedIn);
                setIsLoading(false);
            })
            .catch(() => {
                setIsAuthenticated(false);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return <div>Loading...</div>; // Or some other loading indicator
    }
    // console.log(isAuthenticated)
    return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
}

export default AuthenticatedRoute