const CreateAccount = async(email, company_name, full_name) => {
  
	const BASE_URL = process.env.REACT_APP_BASE_URL;
  const data = {
    "email": email,
    "company_name": company_name,
    "full_name": full_name
  }
  try {
    const response = await fetch(`${BASE_URL}/signup/create_account/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' // Ensure you set the content type header
      },
      body: JSON.stringify(data)
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
		throw new Error(error.message);
  }
}

export default CreateAccount