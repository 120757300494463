// LoadingIcon.jsx
import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const LoadingIcon = ({ size = 24, color = 'text-orange-500' }) => (
  <div className={`${color}`}>
    <FaSpinner size={size} className='animate-spin' />
  </div>
);

export default LoadingIcon;
