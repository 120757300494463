import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import {
  IoIosArrowBack,
  IoIosArrowForward
} from "react-icons/io";

import Radio from '../../../../components/Radio';
import Checkbox from '../../../../components/Checkbox';

import { updateFilterSort }
  from '../../../../redux/users/filters/filterSortSlice';
import { updateFilterActivity }
  from '../../../../redux/users/filters/filterActivitySlice';
import { updateFilterDate }
  from '../../../../redux/users/filters/filterDateSlice';
import { checkIfMobile, updateSideBarState } from
  '../../../../redux/users/componentState/responsiveSlice';

import CustomDateRangePicker from '../../../../components/CustomDateRangePicker';

export default function Filters() {

  const selectedSort = useSelector((state) => state.filterSort.value)
  const selectedActivity = useSelector((state) => state.filterActivity.value)
  const isSidebarOpen = useSelector((state) => state.sidebar.isSidebarOpen)

  const dispatch = useDispatch();

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    dispatch(updateFilterActivity({ id, checked }));
  };

  const onDateChange = (startDateParams, endDateParams) => {
    // console.log(startDateParams);
    // console.log(endDateParams);
    dispatch(updateFilterDate({ startDate: startDateParams, endDate: endDateParams }));
  }

  const callCheckIfMobile = useCallback(() => {
    dispatch(checkIfMobile());
  }, [dispatch]);

  useEffect(() => {
    
    callCheckIfMobile();

    window.addEventListener('resize', callCheckIfMobile);

    return () => {
      window.removeEventListener('resize', callCheckIfMobile);
    };
  }, [callCheckIfMobile]);

  return (
    <>
    {/* Sidebar toggle button */}
    <button
      className={`absolute ${isSidebarOpen ? 'left-64 md:hidden' : 'left-0'}  
        p-0 z-10 top-1/2`}
      // onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      onClick={() => dispatch(updateSideBarState(!isSidebarOpen))}
    >
    {isSidebarOpen ?
      <div
        className='border-2 border-orange-500 h-16 rounded-md'
      >
        <IoIosArrowBack
          size={7}
          className='h-14 text-orange-500 font-bold'
        />
      </div>
      :
      <div
        className='border-2 border-orange-500 h-16 rounded-md'
      >
        <IoIosArrowForward
          size={7}
          className='h-14 text-orange-500 font-bold'
        />
      </div>
    }
    </button>
      
    <div className={`transform w-64 bg-white dark:bg-gray-700 p-3 fixed
       h-full ease-in-out transition-all duration-300 ${
          isSidebarOpen ? "translate-x-0 md:w-2/12 p-3" : "-translate-x-full md:w-0"
        } md:translate-x-0 md:static overflow-y-auto z-10
      `}>

      <div>
        <CustomDateRangePicker onDateChange={onDateChange} />
      </div>
      {/* SIMPLE SORT LABEL */}
      <div className="flex justify-between mt-5">
        <div className='font-bold text-sm text-gray-600 dark:text-white'>
          Sort
        </div>
      </div>
      {/* SORT THE USERS ACCORDING TO LAST ACTIVITY,
      TOP POSTERS, TOP VOTERS */}
      <div className='mt-3'>
        <Radio
          id="last_activity"
          name="activity"
          checked={selectedSort === 'last_activity'}
          onChange={(event) => dispatch(updateFilterSort(event.target.id))}
          label="Last activity"
        />

        <Radio
          id="top_poster"
          name="activity"
          checked={selectedSort === 'top_poster'}
          onChange={(event) => dispatch(updateFilterSort(event.target.id))}
          label="Top posters"
        />

        <Radio
          id="top_voters"
          name="activity"
          checked={selectedSort === 'top_voters'}
          onChange={(event) => dispatch(updateFilterSort(event.target.id))}
          label="Top voters"
        />

      </div>

      {/* SIMPLE ACTIVITY LABEL */}
      <div className="flex justify-between mt-5">
        <div className='font-bold text-sm text-gray-600 dark:text-white'>
          Activity
        </div>
      </div>
      
      <div className="mt-3">
        <Checkbox
          id="posts"
          checked={selectedActivity.posts || false}
          onChange={handleCheckboxChange}
          label="Posts"
        />

        <Checkbox
          id="votes"
          checked={selectedActivity.votes || false}
          onChange={handleCheckboxChange}
          label="Votes"
        />

        <Checkbox
          id="comments"
          checked={selectedActivity.comments || false}
          onChange={handleCheckboxChange}
          label="Comments"
        />
      </div>
    </div>
    </>
  );
}