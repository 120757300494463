import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isPostOpen: false,
  selectedUser: {},
}

export const userPostSlice = createSlice({
  name: 'userPost',
  initialState,
  reducers: {
    updateUserPostState: (state, action) => {
      state.isPostOpen = action.payload
    },
    updateSelectedUser: (state, action) => {
      state.selectedUser = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateUserPostState, updateSelectedUser } = userPostSlice.actions

export default userPostSlice.reducer