import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link, useLocation } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";

export default function Success() {
  const location = useLocation();
  const slug = location.state?.slug;
  const board_url = window.location.protocol + '//' + window.location.host + '/' + slug;
  const admin_board_url = window.location.protocol + '//' + window.location.host + '/admin/feedback/' + slug;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Create your first board | Feedvoty</title>
        </Helmet>
        <div className="w-full flex items-center justify-center h-screen bg-gray-50">
          <div className="p-8 rounded-lg w-full md:w-5/12">

            <div className="flex justify-center">
              <img src="../icons/party-success.png" alt="" className='w-2/12' />
            </div>
            <h2 className="text-2xl font-bold text-center mb-4">
              Congratulation! <br />
              You created your First Board
            </h2>

            <div className='text-center'>
              This is your feedvoty domain that you can share with users and team members
            </div>

            <div className='text-center mt-5'>
              <a href={board_url} target="_blank" rel="noreferrer"
                className='inline-flex items-center justify-center'>
                {board_url} <FaExternalLinkAlt className='ml-2' />
              </a>
            </div>

            <div className='text-center mt-10'>
              <Link to={admin_board_url}
                className='bg-orange-500 px-5 py-3 rounded text-white'>
                GO TO BOARD
              </Link>
            </div>

          </div>
        </div>
      </HelmetProvider>
    </>
  );
}