import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdMenu } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { IoEyeOutline, IoNotificationsOutline } from "react-icons/io5";
import AuthenticatedService from '../services/AuthenticatedService';

// Navbar component
const Navbar = () => {
  let links = [
    // {name: "Dashboard", link: "/admin"},
    {name: "Feedback", link: "/admin/feedback"},
    {name: "Roadmap", link: "/admin/roadmap"},
    {name: "Users", link: "/admin/users"},
    // {name: "Changelog", link: "/admin/changelog"},
    // {name: "Reports", link: "/admin/reports"},
  ];

  let [open, setOpen] = useState(false);
  let [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  let [notificationDropdownOpen, setNotificationDropdownOpen] = useState(false);

  const profileDropdownRef = useRef();
  const notificationDropdownRef = useRef();

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      AuthenticatedService.removeTokens(window.location.hostname);
      navigate('/login'); // Redirect to login after logout
    } catch (error) {
      console.error('Logout failed', error);
    }
  };
  
  useEffect(() => {

    const handleClickOutside = (event) => {
      if (profileDropdownOpen && profileDropdownRef.current
          && !profileDropdownRef.current.contains(event.target)) {
        setProfileDropdownOpen(false);
      }
      if (notificationDropdownOpen && notificationDropdownRef.current
          && !notificationDropdownRef.current.contains(event.target)) {
        setNotificationDropdownOpen(false);
      }
    };

    // Bind the the event listner
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [profileDropdownOpen, notificationDropdownOpen]);

  return (
    <div className='shadow-md w-full fixed top-0 left-0 z-50'>
      <div className='md:flex md:items-center md:justify-between bg-orange-500
        dark:bg-gray-700 
        py-3 md:px-10 px-7'>
        <div className='font-bold text-2xl cursor-pointer flex items-center 
          text-gray-600'>
          <span className='mr-1'>
            <Link to="/admin">
              <img src="/logo.png" alt="" className='w-1/3' />
            </Link>
          </span> 
        </div>
        <div onClick={() => setOpen(!open)} className='text-3xl absolute 
          left-20 top-4 cursor-pointer text-white
          md:hidden'>
          {
            open ? <IoMdClose /> : <MdMenu />
          }
        </div>
        <ul className={`md:flex md:items-center md:pb-0 pb-2 absolute 
          md:static md:shadow-none shadow-md bg-white md:bg-orange-500
          dark:md:bg-gray-700
          rounded-lg md:z-auto z-50 left-0 w-full md:pl-0 pl-7
          transition-all duration-500 ease-in ${open ? 'z-50 top-20 opacity-100' :
            'top-[-490px]'} 
          md:opacity-100 opacity-100`}>
          {
            links.map((link) => (
              <li key={link.name} className='md:ml-2 text-lg md:my-0 my-7'>
                <Link to={link.link}
                  className='md:text-white text-gray-800
                    hover:bg-orange-600 md:px-4 md:py-2 font-medium rounded-md
                    duration-500'>
                  {link.name}
                </Link>
              </li>
            ))
          }
        </ul>

        <ul className='flex items-center absolute right-8 md:top-3 top-3'>
          <li className='md:mr-5 mr-3 p-2 bg-orange-600 text-white 
            rounded-full hover:bg-orange-700'>
            <Link to="/">
              <IoEyeOutline className='text-2xl' />
            </Link>
          </li>

          <div className='relative' ref={notificationDropdownRef}>
            <li className='md:mr-5 mr-3 p-2 bg-orange-600 text-white 
              rounded-full cursor-pointer hover:bg-orange-700'
              onClick={() => setNotificationDropdownOpen(!notificationDropdownOpen)}
            >
              <IoNotificationsOutline className='text-2xl' />
            </li>
            {notificationDropdownOpen && (
              <ul className='absolute right-0 mt-2 py-2 w-56 md:w-96 bg-white 
                rounded-lg shadow-xl z-50'>
              <li className='px-4 py-2 cursor-pointer'>
                You have no notification
              </li>
            </ul>
            )}
          </div>
          
          <div className='relative' ref={profileDropdownRef}>
            <li className='p-1.5 bg-orange-600 text-white rounded-full 
              w-full text-center cursor-pointer hover:bg-orange-700'
              style={{ width: '40px', height: '40px' }}
              onClick={() => setProfileDropdownOpen(!profileDropdownOpen)}>
              <span className='text-xl'>R</span>
            </li>

            {profileDropdownOpen && (
            <ul className='absolute right-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-xl'>
              <li className='px-4 py-2 hover:text-orange-500 cursor-pointer'>
                <Link to="/admin/profile">Profile</Link>
              </li>
              <li className='px-4 py-2 hover:text-orange-500 cursor-pointer'>
                <Link to="/admin/settings">Settings</Link>
                </li>
              {/* Divider */}
              <li role="separator" className='my-2'>
                <hr className='border-t border-gray-300' />
              </li>
              <li className='px-4 py-2 hover:text-orange-500 cursor-pointer'
                onClick={handleLogout} >
                Logout
              </li>
            </ul>
            )}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
