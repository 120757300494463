import React from 'react';

const Radio = ({ id, name, checked, onChange, label }) => {
  return (
    <div className="flex items-center">
      {/* Hidden native radio button */}
      <input
        type="radio"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        className="hidden peer"
      />
      {/* Custom radio button */}
      <label htmlFor={id} className="flex items-center cursor-pointer p-1">
        <span
          className={`w-5 h-5 border-2 border-gray-300 rounded-full flex 
            items-center justify-center
            peer-checked:border-orange-600 peer-checked:bg-orange-600
            ${checked ? 'border-orange-600 bg-orange-600' : ''}`}
        >
        <span
          className={`w-3 h-3 rounded-full bg-white dark:bg-gray-700 
            ${checked ? 'block' : 'hidden'}`}
        />
        </span>
        <span className="ml-2 text-gray-800 dark:text-white">{label}</span>
      </label>
    </div>
  );
};

export default Radio;
