import React from 'react';
import { useParams } from 'react-router-dom';

export default function Feedback() {
	const { boardslug } = useParams();
  return (
    <>
      <div>
      Feedback { boardslug }
      </div>
    </>
  );
}