import React from 'react';
import Navbar from '../../../components/Navbar';

import Filters from './components/Filters';
import UserList from './components/UserList';

import { userStore } from '../../../redux/users/userStore';
import { Provider } from 'react-redux';
import UserPosts from './components/UserPosts';
import UserDetails from './components/UserDetails';

export default function Users() {

  return (
    <>
      <Navbar />
      <div className="flex mt-[60px] overflow-y-hidden z-10"
        style={{ height: 'calc(100vh - 60px)' }}>
        <Provider store={userStore}>
          <Filters />
          <UserList />
          <UserPosts />
          <UserDetails />
        </Provider>
      </div>
    </>
  );
}
