import { useSelector } from 'react-redux'
import { AiFillPlusCircle } from "react-icons/ai";
import { IoMdArrowDropup } from "react-icons/io";
import formatDateTime from '../utils/formatDatetime';

export default function UserDetails() {

  const selectedUser = useSelector((state) => state.userPost.selectedUser)

  return (
    <div className='
      hidden md:flex flex-col 
      md:w-2/12 overflow-y-auto border
      dark:bg-gray-800 dark:text-white'
    >
      {selectedUser.full_name && (
        <div>
          <div className="flex space-x-3 p-3">
            <div className="rounded-full bg-gray-500 text-white flex items-center justify-center h-10 w-10">
              {selectedUser.full_name.charAt(0).toUpperCase()}
            </div>
            <div>
              {selectedUser.full_name.split(' ')[0]}
              <div className="flex justify-between">
                <div className='flex'>
                  <AiFillPlusCircle
                    size={20}
                    className='text-gray-500 dark:text-gray-300 pt-1 mr-2'
                  />
                  <span className='mt-1 text-sm text-gray-500 dark:text-gray-300'>
                    {selectedUser.post_count}
                  </span>

                  <IoMdArrowDropup
                    size={28}
                    className='ml-5 mr-1 text-gray-500 dark:text-gray-300'
                  />
                  <span className='mt-1 text-sm text-gray-500 dark:text-gray-300'>
                    {selectedUser.vote_count}
                  </span>
                </div>
                
              </div>
            </div>
          </div>

          <div className='border-gray-300 border-t p-3'>
            <div className='text-gray-400'>
              Email
            </div>
            <div className='text-gray-700'>
              { selectedUser.email }
            </div>

            <div className='text-gray-400 mt-3'>
              Full name
            </div>
            <div className='text-gray-700'>
              { selectedUser.full_name }
            </div>

            <div className='text-gray-400 mt-3'>
              Last activity
            </div>
            <div className='text-gray-700'>
              { formatDateTime(selectedUser.latest_activity) }
            </div>
          </div>
        </div>
      )}
    </div>
  );
}