import {AiOutlineClose} from 'react-icons/ai';

const ImageViewer = ({isOpen, onClose, value}) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      {/* Black shadow */}
      <div
        className={`fixed inset-0 bg-black opacity-50 dark:opacity-80 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={onClose}
      ></div>
  
      <div className="bg-white dark:bg-gray-800 dark:text-gray-200
        rounded shadow-md 
        relative t-0 w-full 
        md:w-8/12 max-h-full overflow-y-auto">
        <div className="flex justify-between items-center p-3 md:p-5">
          <span onClick={onClose} className="cursor-pointer">
            <AiOutlineClose size={23} />
          </span>  
        </div>
        <div>
          <img src={value} alt="" />
        </div>
      </div>
    </div>
  )
}

export default ImageViewer;