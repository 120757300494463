import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { formatDateTime } from '../utils/helpers';
import { UpdateComment } from '../utils/api';

const Comment = ({ postComment, handleCommentRemove, topLevelParentId, setTopLevelParentId, saveComment }) => {
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [reply, setReply] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedComment, setEditedComment] = useState(postComment.comment);
  
  // TOGGLE EDIT MODE
  const toggleEditMode = () => {
    setIsEditing(!isEditing);
    setEditedComment(postComment.comment); // Reset edited comment to current comment when entering edit mode
  };
  
  const handleEditChange = (e) => {
    setEditedComment(e.target.value);
  };
  
  // SAVE EDITED COMMENT
  const saveEditedComment = async () => {
    if (editedComment.trim() === '') {
      // alert('Comment cannot be empty.');
      toast.error("Comment cannot be empty!");
      return;
    }
  
    const commentData = {
      comment_id: postComment.comment_id,
      comment: editedComment,
      additional_note: null
      // Include other necessary data for updating a comment
    };
  
    // Call a function to update the comment in your system
    const updatedComment = await UpdateComment(commentData); // Implement this function based on your backend or state management
    // console.log(updatedComment.status)
    if (updatedComment.status === 200) {
      toast.success("Comment updated");

      postComment.comment = updatedComment.data.comment;
      // updateCommentInState(updatedComment.data.comment, postComment);
      setIsEditing(false);
    }
  };

  // Determine the top-level parent ID to maintain a flat structure
  const toggleReplyForm = () => {
    setShowReplyForm(!showReplyForm);
    // Set the top-level parent ID if it's the first time replying in this thread
    if (!topLevelParentId) {
      setTopLevelParentId(postComment.comment_id);
    }
  };

  const handleReplyChange = (e) => {
    // Prepend @username if starting a new reply
    if (!reply.length) {
      setReply(`@${postComment.owner_full_name} ${e.target.value}`);
    } else {
      setReply(e.target.value);
    }
  };

  const submitReply = async (e) => {
    e.preventDefault();
    // Ensure parent_comment_id is the top-level parent ID for a flat structure
    if (reply.length === 0) {
      toast.error("Comment cannot be empty!");
      return;
    }
    const commentData = {
      comment: reply,
      parent_comment_id: topLevelParentId || postComment.comment_id,
    };
    await saveComment(commentData);
    setReply('');
    setShowReplyForm(false);
  };

  return (
    <li key={postComment.comment_id}>
      <div className='w-full px-3 py-2 my-1 rounded-md bg-gray-100 dark:text-gray-300 dark:bg-gray-700'>
        <div className='flex justify-between'>
          <div>
            <span className='text-gray-800 dark:text-gray-400 font-semibold'>{postComment.owner_full_name}</span>
            {postComment.additional_note && (
              <>
                <span className='text-sm ml-1'>{postComment.additional_note}</span>
                {postComment.status_name && (
                  <span className='text-sm ml-1 px-2 py-1 rounded-md bg-gray-300 dark:bg-gray-500'>{ postComment.status_name} </span>
                )}
                {postComment.board_title && (
                  <span className='text-sm ml-1 px-2 py-1 rounded-md bg-gray-300 dark:bg-gray-500'>{ postComment.board_title} </span>
                )}
              </>
            )}
          </div>
          
        </div>
        
        {isEditing ? (
          <textarea
            value={editedComment}
            onChange={handleEditChange}
            className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-orange-500 focus:outline-none bg-gray-50 dark:bg-gray-800 dark:text-gray-200 focus:bg-white dark:focus:bg-gray-700 overflow-hidden resize-none'
            rows={2}
          ></textarea>
        ) : (
          <div className='text-gray-700 dark:text-gray-300'>
            {postComment.comment}
          </div>
        )}
        
        <div className='flex justify-between mt-2 text-sm'>
          <div className='flex'>
            <div className='mr-5 text-sm text-gray-500 dark:text-gray-400'>
              {formatDateTime(postComment.comment_datetime)}
            </div>
            {isEditing ? (
              <>
                <button type='button' className='pr-3' onClick={saveEditedComment}>Save</button>
                <button type='button' className='pr-3' onClick={toggleEditMode}>Cancel</button>
              </>
            ) : (
              <>
                <button type='button' className='pr-3' onClick={toggleEditMode}>Edit</button>
                <button type='button' className='px-3' onClick={toggleReplyForm}>Reply</button>
              </>
            )}
          </div>
          <button type="button" className='text-red-500' onClick={() => handleCommentRemove(postComment.comment_id)}>Delete</button>
        </div>
      </div>

      {showReplyForm && (
        <form onSubmit={submitReply} className="mt-2">
          <textarea
            value={reply}
            onChange={handleReplyChange}
            className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-orange-500 focus:outline-none bg-gray-50 dark:bg-gray-800 dark:text-gray-200 focus:bg-white dark:focus:bg-gray-700 overflow-hidden resize-none'
            placeholder="Type your reply..."
            rows={2}
          ></textarea>
          <div className="w-full flex justify-between">
            <button
              type="button"
              className='dark:text-gray-300 px-3'
              onClick={() => setShowReplyForm(false)}
            >
              Cancel
            </button>
            <button type="submit" className="bg-orange-500 hover:bg-orange-600 text-white rounded-md px-5 py-2 mt-2">Submit</button>
          </div>
        </form>
      )}

      {postComment.child_comments && postComment.child_comments.map((childComment, index_child) => (
        <ul className='ml-10 my-1 rounded-md bg-gray-100 dark:text-gray-300 dark:bg-gray-700'>
          <Comment
              key={postComment.comment_id + index_child}
              postComment={childComment}
              handleCommentRemove={handleCommentRemove}
              topLevelParentId={topLevelParentId || postComment.comment_id}  // Pass down the top-level parent ID
              setTopLevelParentId={setTopLevelParentId}
              saveComment={saveComment}
            />
        </ul>
      ))}
    </li>
  );
};

export default Comment;
