import React, { useEffect, useState } from 'react';

const RemoveConfirmationModal = ({ id, value, isOpen, onCancel, onConfirm }) => {

  const [deleteField, setDeleteField] = useState("");
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    setDeleteField("");
    setValidationError("");
  }, [isOpen, setDeleteField, setValidationError]);

  if (!isOpen) {
    return null;
  }

  const checkDelete = () => {
    if (deleteField.trim() === 'delete') {
      setValidationError("");
      onConfirm(id);
      // console.log(deleteField)
    } else {
      setValidationError("type delete in small case");
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50" onClick={onCancel}></div>
      <div className="bg-white dark:bg-gray-800 dark:text-gray-300 p-5 
        rounded shadow-md relative t-0 w-full md:w-1/2">
        <h2 className="text-xl font-bold mb-4">Confirm {value} delete</h2>
        <p className="mb-4">
          Are you sure you want to delete this {value}?
          Type <i>delete</i> in the field to confirm delete.
        </p>
        <div className='my-3'>
          <input
            type="text"
            className={`p-3 rounded-md w-full border-2 border-solid
              focus:border-orange-500 focus:outline-none dark:bg-gray-800
              ${validationError ? 'border-red-500' : 'border-orange-400'}
              placeholder:italic placeholder:gray-100`}
            placeholder='delete'
            onKeyUp={(e) => setDeleteField(e.target.value)}
          />
          {validationError && <p className="text-red-500">
          {validationError}</p>}
        </div>
        <div className="flex justify-between">
          <button
            className="px-4 py-2 text-gray-700 dark:text-gray-300 rounded"
            onClick={onCancel}>
            Cancel
          </button>
          <button
            className="ml-3 px-4 py-2 bg-red-500 text-white rounded"
            onClick={checkDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveConfirmationModal;
