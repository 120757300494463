import AuthenticatedService from '../../../services/AuthenticatedService';

const CreatePassword = async(password) => {
	const BASE_URL = process.env.REACT_APP_BASE_URL;
  const data = {
    "new_password": password,
  }
  const token = AuthenticatedService.getToken(); 
  
  try {
    const response = await fetch(`${BASE_URL}/users/set_password/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        status: response.status, // Include the status in the returned object
        data: responseData.detail // Include the response data
      };
		  // throw new Error(responseData.detail);
    }

    return {
      status: response.status, // Include the status in the returned object
      data: responseData       // Include the response data
    };
  
  } catch (error) {
    console.error(error);
		throw new Error(error.message);
  }
}

export default CreatePassword;